
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pacifico&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pacifico&family=Roboto+Slab:wght@100..900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@font-face {
  font-family: proximaFont;
  src: url(./components/Assets/Demo_Fonts/Fontspring-DEMO-proximanova-medium.otf);
}


p,h1,h2,h3,h4,h5,h6,a,button,span{
  font-family: "Poppins", sans-serif !important;
  /* font-family: proximaFont !important; */
  /* font-weight: 600 !important; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.body_main{
  background-color: #393535;
  overflow-x: hidden;
}

a{
  cursor: pointer;
  text-decoration: none;
}

.main_body{
  flex-direction: column;
  overflow: hidden;
}

body::-webkit-scrollbar{
  width: 7px;
  /* background-color: red; */
}

body::-webkit-scrollbar-thumb{
  width: 7px;
  background-color: #fbe5ca;
  border-radius: 10px;
}

body::-webkit-scrollbar-track{
  width: 7px;
  background-color: #393535;
  border-radius: 10px;
}

textarea::-webkit-scrollbar{
  display: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #393535 inset !important;
    -webkit-text-fill-color: #fff !important;
    caret-color: White !important;
}

input{
  font-weight: 600 !important;
}

.common_top_Section.Top_seciton {
  /* height: 300px; */
  padding: 50px 60px 240px 60px !important;
}

body{
  scroll-behavior: auto !important;
}

:root {
  scroll-behavior: smooth;
}

@media(max-width:767px){
  .common_top_Section.Top_seciton {
    padding: 40px 0px 180px 0px !important;
}

.common_top_Section.Top_seciton .img_div {
  /* width: 90%; */
  /* height: 300px; */
  top: 156px !important;
}
}

@media(max-width:576px){
  
input{
  height: 49px !important;
  border-radius: 9px !important;
}

.common_top_Section.Top_seciton .breadcrumbs a{
  width: max-content !important;
}

}

@media(max-width:335px){
  
.register_main .Top_seciton .breadcrumbs a{
  margin: 0px 7px !important;
  width: min-content !important;
}
}