

/* ----------------------------------------------------------  Top_seciton  -------------------------------------------- */

.service_main .Top_seciton{
    /* height: 300px; */
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px 60px 240px 60px;
    position: relative;

}

.service_main .Top_seciton .img_div{
    position: absolute;
    top: 190px;
    width: 85%;
    left: 50%;
    transform: translate(-50%,0);
    height: 500px;
}

.service_main .Top_seciton .img_div img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
}
/* 
.service_main .Top_seciton{
    height: 300px;
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px;
} */

.service_main .Top_seciton .main_title {
    color: rgba(234, 181, 20, 1);
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.service_main .Top_seciton .breadcrumbs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}

.service_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow{
    color: rgba(234, 181, 20, 1);
    font-size: 26px;
    position: relative;
    cursor: pointer;
}
.service_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow:hover{
    transform: translateX(0.5rem);
}
.service_main .Top_seciton .breadcrumbs a{
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 0px 15px;
    text-transform: uppercase;
}


/* ----------------------------------------------------------  our_service  -------------------------------------------- */


.our_service{
    width: 96%;
    margin: auto;
    margin-top: 400px;
    text-align: center;
}

.our_service > h5{
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 12px;
    /* text-transform: uppercase; */

}

.our_service > h2{
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.our_service .our_service_row {
    width: 91%;
    margin: auto;
    margin-top: 80px;
}

.our_service .our_service_row .col_main .card{
    background: rgba(81, 78, 78, 1);
    box-shadow: 4px 3px 10px rgb(0 0 0 / 19%);
    border: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 65px 25px 31px 25px;
    text-align: left;
    width: 92%;
    margin: auto;
    margin-bottom: 100px;
    height: 370px;
}

.our_service .our_service_row .col_main .card .img_div{
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background: linear-gradient(rgba(234, 181, 20, 1),rgba(192, 87, 11, 1));
    position: absolute;
    top: -32px;
    left: 33px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.our_service .our_service_row .col_main .card .img_div img{
    width: 47%;
}

/* .our_service .our_service_row .col_main .card .img_div img{
    width: 100%;
    height: 100%;
} */

.our_service .our_service_row .card h5{
    color: rgba(234, 181, 20, 1);
    font-size: 22px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
}

.our_service .our_service_row .card p{
    color: rgba(255, 255, 255, 1);
    font-size: 17px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    margin-top: 10px;
}

.our_service .our_service_row .card a{
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-top: 15px;
    position: absolute;
    bottom: 33px;
    z-index: 10;
}

.our_service .our_service_row .card a img{
    margin-left: 11px;
    position: relative;
    bottom: 3px;
    width: 21px;
}

.our_service .our_service_row .card a::before{
    content: "";
    position: absolute;
    right: 0px;
    height: 23px;
    width: 2px;
    background: rgba(234, 181, 20, 1);
    transform: rotate(26deg);
    display: none;

}

.our_service .our_service_row .card a::after{
    content: "";
    position: absolute;
    right: -11px;
    transform: rotate(27deg);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 9px solid rgba(234, 181, 20, 1);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    top: -4px;
    display: none;
}

.our_service .our_service_row .card a .learn_more_arrow{
    font-size: 39px;
    position: relative;
    top: -4px;
    transform: rotate(312deg);
}


/* ----------------------------------------------------------  service_our_process  -------------------------------------------- */


.service_our_process{
    width: 100%;
    margin: auto;
    margin-top: 10px;
    text-align: center;
}

.service_our_process > h6{
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    /* text-transform: uppercase; */
}

.service_our_process > h2{
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 50px;
    margin-top: 10px;
}

.service_our_process .service_our_process_row{
    width: 96%;
    margin: auto;
    margin-top: 20px;
    justify-content: center;
}

.service_our_process .service_our_process_row .col_main{
    padding: 0;
    margin-top: 30px;
}

.service_our_process .service_our_process_row .col_main .card{
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    border: none;
}

/* .service_our_process .service_our_process_row .col_main:last-child .card::before{
    display: none;
}

.service_our_process .service_our_process_row .col_main .card::before{
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    left: 0;
    top: 30px;
    z-index: 2;
} */

.service_our_process .service_our_process_row .col_main .card .img_div{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.service_our_process .service_our_process_row .col_main .card .img_div img{
    width: 100%;
    height: 100%;
}

.service_our_process .service_our_process_row .col_main .card .img_div span{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: rgba(234, 181, 20, 1);
    color: rgba(57, 53, 53, 1);
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    top: 54px;
    position: absolute;
    right: -25px;
}

.service_our_process .service_our_process_row .col_main .card h6{
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    margin: 20px 0px 10px;
}

.service_our_process .service_our_process_row .col_main .card p{
    color: rgba(248, 242, 242, 1);
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    width: 80%;
    text-align: center;
    margin: auto;
    text-transform: capitalize;
}


/* ----------------------------------------------------------  service_testimonials  -------------------------------------------- */


.service_testimonials_row{
    width: 91% !important;
    margin: auto;
    margin-top: 110px;
    margin-bottom: 110px;
}

.service_testimonials_row .col_main_1 .img_div{
    position: relative;
    width: 430px;
    margin: auto;
}

.service_testimonials_row .col_main_1 .img_div img{
    width: 100%;
    /* transform: scaleX(-1); */
    margin: auto;
    display: block;
}


.service_testimonials_row .col_main_2{
    padding-left: 0px;
}

.service_testimonials_row .col_main_2 .testimonials_top_content{
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    padding-top: 25px;
}


.service_testimonials_row .col_main_2 .testimonials_top_content > h3{
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    display: none;
}

.service_testimonials_row .col_main_2 .testimonials_top_content > h5{
    color: rgba(248, 248, 248, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin: 15px 0px 11px 0px;
}

.service_testimonials_row .col_main_2 .testimonials_top_content > p{
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    margin-bottom: 25px;
    margin-top: 15px;
    text-transform: capitalize;
}

.service_testimonials_row .col_main_2 .testimonials_top_content, .service_testimonials_row .testimonials_bottom_content{
    width: 83%;
}


.service_testimonials_row .testimonials_bottom_content{
    background: rgba(81, 78, 78, 1);
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 40px 30px 30px;
    /* width: 83%; */
    /* margin: auto; */
    /* margin-top: 50px; */
    position: relative;
}

.service_testimonials_row .testimonials_bottom_content::after{
    content: url(../../Assets/quotation_yellow.svg);
    position: absolute;
    right: 34px;
    bottom: 20px;
}

.service_testimonials_row .testimonials_bottom_content > p{
    color: rgba(248, 248, 248, 1);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 55px;
}

.service_testimonials_row .testimonials_bottom_content .img_div_main{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.service_testimonials_row .testimonials_bottom_content .img_div_main .img_div{
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 50%;
    margin-right: 20px;
}

.service_testimonials_row .testimonials_bottom_content .img_div_main .img_div img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.service_testimonials_row .testimonials_bottom_content .img_div_main > content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service_testimonials_row .testimonials_bottom_content .img_div_main h6{
    color: rgba(248, 248, 248, 1);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.service_testimonials_row .testimonials_bottom_content .img_div_main p{
    color: rgba(248, 248, 248, 1);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    /* text-transform: capitalize; */
    margin-bottom: 0;
    margin-top: 6px;
}


.testimonials_carousel_service .react-multiple-carousel__arrow--left {
    left: 0px;
    bottom: 0;
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
}

.testimonials_carousel_service .react-multiple-carousel__arrow--right {
    left: 60px;
    bottom: 0;
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    right: initial;
}

.testimonials_carousel_service .react-multiple-carousel__arrow--left:hover,
.testimonials_carousel_service .react-multiple-carousel__arrow--right:hover {
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    opacity: 0.5;
}
/* ----------------------------------------------------------  media querry  -------------------------------------------- */


@media(min-width:1600px){
    .service_main .Top_seciton .img_div img{
        width: 85%;
    }
}

@media(max-width:1300px){
    .our_service .our_service_row .col_main .card {
        width: 95%;
        height: 400px;
    }

    .service_testimonials_row {
        width: 95% !important;
        margin:110px auto;
    }
    
    .service_testimonials_row .col_main_2 .testimonials_top_content, .service_testimonials_row .testimonials_bottom_content {
        width: 90%;
        margin: auto;
    }
    
    .service_testimonials_row .testimonials_bottom_content {
        margin-top: 50px;
    }
        
}

@media(max-width:1200px){
    
    .service_testimonials_row{
        margin-top: 100px;
    }

    .service_testimonials_row .col_main_1 .img_div img{
        width: 100%;
    }

    .our_service .our_service_row .col_main .card {
        width: 100%;
        height: 470px;
        margin-bottom: 70px;
    }

    .service_testimonials_row .col_main_1 .img_div {
        width: 100%;
    }

}

@media(max-width:1000px){
    
    .service_main .Top_seciton .img_div {
        width: 90%;
        height: 400px;
    }
    
    .service_main .Top_seciton .img_div img {
        width: 100%;
    }

    .our_service {
        margin-top: 290px;
    }

}

@media(max-width:991px){

    .service_our_process .service_our_process_row .col_main .card::before{
        display: none;
    }

    .service_testimonials_row .col_main_2{
        padding-left: 29px;
    }

    .service_testimonials_row .col_main_1 {
        margin: auto;
    }  
    
    .service_testimonials_row .col_main_2 {
        padding-left: 0;
        margin-top: 0px;
    }

    .service_testimonials_row .col_main_1 .img_div {
        width: 100%;
        height: 451px;
    }

    .service_testimonials_row .col_main_1 .img_div img {
        width: 100%;
        margin: auto;
        object-fit: cover;
        height: 100%;
        border-radius: 20px;
    }

    .service_testimonials_row .col_main_2 .testimonials_top_content{
        padding-top: 0px;
    }
    
    .our_service .our_service_row .col_main .card {
        width: 100%;
        height: 350px;
    }

    .our_service .our_service_row .card p {
        font-size: 15px;
    }

    .service_testimonials_row .testimonials_bottom_content {
        padding: 30px 30px;
        margin-top: 42px;
    }

    .service_testimonials_row .testimonials_bottom_content > p {
        margin-bottom: 35px;
    }

    .service_testimonials_row .testimonials_bottom_content .img_div_main .img_div {
        width: 65px;
        height: 65px;
        min-width: 65px;
        min-height: 65px;
    
    }

}


@media(max-width:767px){

    .service_main .Top_seciton {
        padding: 69px 0px 203px 0px;
    }

    .service_main .Top_seciton .main_title{
        font-size: 30px;
    }

    .service_main .Top_seciton .breadcrumbs a{
        font-size: 18px;
    }

    .service_our_process > h6{
        font-size: 22px;
    }

    .service_our_process > h2 {
        font-size: 29px;
    }

    .service_our_process > h2{
        width: 97%;
        margin: auto;
    }

    .service_our_process .service_our_process_row .col_main .card p{
        font-size: 16px;
        width: 96%;
    }

    .service_our_process .service_our_process_row .col_main .card h6{
        font-size: 18px;
    }

    .service_our_process .service_our_process_row{
        width: 90%;
    }

    .service_testimonials_row .col_main_2 {
        padding-left: 0;
        margin-top: 60px;
    }

    .service_testimonials_row .col_main_1 {
        margin: auto;
    }

    .service_testimonials_row .col_main_1 .img_div img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    
    .service_testimonials_row .col_main_2 .testimonials_top_content, .service_testimonials_row .testimonials_bottom_content {
        width: 90%;
        margin: auto;
    }

    .service_testimonials_row .testimonials_bottom_content > p {
        margin-bottom: 50px;
    }
        
    .service_testimonials_row .testimonials_bottom_content{
        margin-top: 50px ;
    }

    .our_service .our_service_row .col_main .card{
        height: auto;
    }

    .our_service .our_service_row .card a {
        bottom: 0;
        position: relative;
        font-size: 18px;
    }

    .our_service > h2{
        font-size: 29px;
    }

    .our_service > h5{
        font-size: 20px;
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }

    .our_service .our_service_row .card h5 {
        font-size: 20px;
    }

    .our_service .our_service_row .card p{
        font-size: 16px;
    }

    .our_service .our_service_row .card a img {
        margin-left: 6px;
        bottom: 2px;
        width: 16px;
    }

    .service_testimonials_row .col_main_1 .img_div {
        width: 58%;
        height: 451px;
    }    
     
}

@media(max-width:575px){

    .service_main .Top_seciton .img_div {
        width: 90%;
        height: 300px;
        top: 196px;
    }

    .our_service {
        margin-top: 215px;
    }

    .service_our_process > h6{
        font-size: 20px;
        width: 90%;
        margin: auto;
        margin-top: 3px;
    }

    .service_our_process > h2{
        font-size: 28px;
    }

    .service_our_process .service_our_process_row{
        width: 89%;
    }

    .service_our_process .service_our_process_row .col_main .card p{
        width: 100%;
    }

    .service_testimonials_row{
        width: 100% !important;
    }
    
    .service_testimonials_row .col_main_1 .img_div {
        width: 95%;
        /* margin: auto; */
        height: initial;
    }

    .service_testimonials_row .col_main_2 .testimonials_top_content > h3 {
        font-size: 18px;
    }

    .service_testimonials_row .col_main_2 .testimonials_top_content > h5 {
        font-size: 21px;
    }

    .service_testimonials_row .col_main_2 .testimonials_top_content > p {
        font-size: 16px;
    }
    
    .service_testimonials_row .col_main_1 .img_div img {
        width: 100%;
        height: 100%;
    }

    .service_testimonials_row .col_main_1 .img_div{
        width: 95%;
        height: 400px;
    }

    .our_service .our_service_row .col_main .card .img_div {
        width: 60px;
        height: 60px;
    }

    .our_service .our_service_row .col_main .card .img_div img {
        width: 43%;
    }

    .our_service .our_service_row .col_main .card {
        padding: 50px 25px 31px 25px;
    }
    
    .service_our_process .service_our_process_row .col_main {
        margin-top: 45px;
    }
    
    .service_testimonials_row .testimonials_bottom_content > p {
        margin-bottom: 30px;
        font-size: 18px;
    }

    .service_testimonials_row .testimonials_bottom_content .img_div_main {
        margin-bottom: 50px;
    }

    .service_testimonials_row .testimonials_bottom_content .img_div_main .img_div {
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
    
    }

    .service_testimonials_row .testimonials_bottom_content::after {
        position: relative;
    right: -6px;
    bottom: 31px;
    float: right;
    }
    
    .service_testimonials_row .testimonials_bottom_content .img_div_main h6 {
        font-size: 18px;
    }

    .service_testimonials_row .testimonials_bottom_content .img_div_main p {
        font-size: 15px;
    }
    

    .service_our_process .service_our_process_row .col_main .card .img_div {
        width: 130px;
        height: 130px;
    }

    .service_our_process .service_our_process_row .col_main .card .img_div span {
        width: 45px;
        height: 45px;
        font-size: 20px;
    }

    .testimonials_carousel_service .react-multiple-carousel__arrow--left {
        /* left: 45%;
        bottom: 0;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%); */
    display: none;
    }
    
    .testimonials_carousel_service .react-multiple-carousel__arrow--right {
        /* left:60%;
        bottom: 0;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        right: initial; */
        display: none;
    }
    
}