input::placeholder,textarea::placeholder{
    font-size: 20px;
}

input[type="number"]:hover::-webkit-inner-spin-button,
input[type="number"]:hover::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
}

/* ----------------------------------------------------------  Top_seciton  -------------------------------------------- */

.register_main .Top_seciton{
    /* height: 300px; */
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px 60px 240px 60px;
    position: relative;

}

.register_main .Top_seciton .img_div{
    position: absolute;
    top: 190px;
    width: 85%;
    left: 50%;
    transform: translate(-50%,0);
    height: 500px;
}

.register_main .Top_seciton .img_div img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
}
/* 
.register_main .Top_seciton{
    height: 300px;
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px;
} */

.register_main .Top_seciton .main_title {
    color: rgba(234, 181, 20, 1);
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.register_main .Top_seciton .breadcrumbs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}

.register_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow{
    color: rgba(234, 181, 20, 1);
    font-size: 26px;
    position: relative;
    cursor: pointer;
}
.register_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow:hover{
    transform: translateX(0.5rem);
}
.register_main .Top_seciton .breadcrumbs a{
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 0px 15px;
    text-transform: uppercase;
}


/* ----------------------------------------------------------  register_description  -------------------------------------------- */


.register_description{
    width: 88%;
    margin: auto;
    margin-top: 400px;
    text-align: left;
}

.register_description > h5{
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 35px;
    text-transform: capitalize;
    width: 70%;
    text-align: left;
    line-height: 32px;
    margin-top: 24px;
}

.register_description > h2{
    color: rgba(255, 255, 255, 1);
    font-size: 34px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-transform: capitalize;
}

.register_form{
    margin-top: 40px;
}

.register_form .input_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.register_form .input_row .input_div {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.register_form .input_row .input_div:last-child{
    float: right;
}

.register_form > .input_div{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
}

.register_form .input_div label{
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    /* text-transform: capitalize; */
}

/* .register_form .input_row .input_div input ,  .register_form > .input_div > textarea{
    color: rgba(234, 181, 20, 1);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
} */

.register_form .input_row .input_div input , .register_form .input_row .input_div input{
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border: 2px solid rgba(234, 181, 20, 1);
    border-radius: 12px;
    background: transparent;
    padding: 2px 17px;
    width: 100%;
    height: 57px;
    margin-top: 10px;
}

.register_form > .input_div input ,  .register_form > .input_div textarea{
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border: 2px solid rgba(234, 181, 20, 1);
    border-radius: 12px;
    background: transparent;
    padding: 2px 17px;
    width: 100%;
    height: 57px;
    margin-top: 12px;
}

.register_form > .input_div textarea{
    height: 150px;
    padding: 10px 17px;
    resize: none;
}

.register_form .register_page_submit{
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    background: rgba(234, 181, 20, 1);
    border-radius: 6px;
    border: none;
    padding: 11px 60px;
    margin: auto;
    display: block;
    margin-top: 60px;
}


/* ----------------------------------------------------------  register_our_values  -------------------------------------------- */


.register_our_values{
    width: 93%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
}

.register_our_values > h5{
    color: rgba(252, 245, 224, 1);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    /* text-transform: uppercase; */
    width: 67%;
    margin: auto;
    line-height: 32px;
}

.register_our_values > h2{
    color: rgba(252, 245, 224, 1);
    font-size: 34px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 20px;
    /* line-height: 50px; */
     text-transform: uppercase;
}

.register_our_values .register_our_values_row{
    width: 100%;
    margin: auto;
    margin-top: 30px;
    justify-content: center;
}

.register_our_values .register_our_values_row .col_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.register_our_values .register_our_values_row .col_main .img_div{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: rgba(234, 181, 20, 1);
    display: flex;
    align-items: center;
    justify-content: center;

}

.register_our_values .register_our_values_row .col_main h5{
    color: rgba(243, 179, 23, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 20px 0px;
    width: 100%;
}

.register_our_values .register_our_values_row .col_main p{
    color: rgba(246, 245, 242, 1);
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    width: 95%;
    line-height: 27px;
    text-transform: capitalize;
    /* text-align: justify; */
    /* word-break: break-all; */
}



/* ----------------------------------------------------------  media querry  -------------------------------------------- */


@media(min-width:1600px){

    .register_main .Top_seciton .img_div img{
        width: 85%;
    }

}

@media(max-width:1200px){
    

}

@media(max-width:1000px){
    
    .register_main .Top_seciton .img_div {
        width: 90%;
        height: 400px;
    }
    
    .register_main .Top_seciton .img_div img {
        width: 100%;
    }

    
    .register_description {
        margin-top: 290px;
    }


}

@media(max-width:991px){

    .register_form .input_row .input_div{
        width: 48%;
    }

    .register_our_values > h5{
        width: 95%;
    }
}


@media(max-width:767px){

    .register_main .Top_seciton {
        padding: 69px 0px 203px 0px;
    }

    .register_main .Top_seciton .main_title{
        font-size: 30px;
    }

    .register_main .Top_seciton .breadcrumbs a{
        font-size: 18px;
    }

    .register_our_values > h2{
        font-size: 29px;
    }

    .register_our_values .register_our_values_row .col_main p{
        width: 100%;
    }

    .register_form .input_row .input_div{
        width: 48%;
    }

    .register_description > h5 {
        width: 100%;
        line-height: 32px;
    }
    
    .register_our_values .register_our_values_row .col_main .img_div {
        width: 75px;
        height: 75px;
    }
    
    
  
}

@media(max-width:575px){

    .register_main .Top_seciton .img_div {
        width: 90%;
        height: 300px;
        top: 180px;
    }

    
    .register_description {
        margin-top: 200px;
    }

    
    .register_form .input_row .input_div{
        width: 100%;
    }

    .register_form .input_row {
        flex-direction: column;
    }

    .register_description{
        width: 90%;
    }

    .register_form .register_page_submit {
        padding: 10px 18px;
        margin: inherit;
        margin-top: 40px;
    }

    .register_description > h2 {
        font-size: 32px;
    }

    .register_description > h5 {
        font-size: 18px;
        margin-bottom: 0px;
        width: 100%;
        line-height: 32px;
        margin-top: 15px;
    }
    
    .register_form {
        margin-top: 30px;
    }

    .register_our_values {
        width: 95%;
        margin: auto;
        margin-top: 75px;
        margin-bottom: 100px;
    }
    
    .register_our_values > h5 {
        font-size: 20px;
        line-height: 30px;
        margin-top: 15px;
    }

    .register_our_values .register_our_values_row .col_main .img_div {
        width: 75px;
        height: 75px;
    }

    .register_main .Top_seciton .breadcrumbs a {
        font-size: 15px;
        margin: 0 5px;
    }

    .register_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow{
        font-size: 15px;
    }
    
    .register_our_values .register_our_values_row .col_main p {
        width: 95%;
        text-align: center;
        /* word-break: break-all; */
    }
    
    .register_our_values .register_our_values_row {
        margin-top: 15px;
    }
    
    
    

}