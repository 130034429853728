.footer{
    background-color: rgba(255, 255, 255, 0.12);
}

.footer_row{
    padding: 15px 15px 50px 40px;
    width: 100%;
}

.footer_content_top{
    margin-top: 50px;
    /* margin-left: 30px; */
    /* margin-right: 30px; */
    align-items: center;
    padding-left: 28px;
    width: 100%;
}

.footer_content_top .img_div{
    position: relative;
    right: 10px;
}

.footer_row p,.footer h5,.footer_row ul li a{
    color: rgba(255, 255, 255, 1);
}

.footer_row ul{
    list-style: none;
    padding: 0;
}


.footer_content_top .company_description{
    color: rgba(255, 255, 255, 1);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
    width: 62%;
    margin-left: 0;
    margin-top: 25px;
    position: relative;
    margin-left: 70px;
    
}

.footer_content_top .company_description::before{
    content: "";
    position: absolute;
    background: url(../../Assets/quotation_white.svg);
    background-repeat: no-repeat;
    top: -7px;
    left: -56px;
    width: 41px;
    height: 28px;
    background-size: cover;

}

.footer_content_top .company_description::after{
    content: "\201D";
    position: absolute;
    margin-left: 10px;
    font-size: 36px;
    bottom: -14px;
}

.footer_row h5{
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
}

.footer_row .usefull_links li span{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(252, 245, 224, 1);
    line-height: 32px;
    cursor: pointer;
}

.footer_row .address_links , .footer_row .contact_links{
    position: relative;
    padding-left: 75px; 
}


.footer_row .address_links .img_div , .footer_row .contact_links .img_div{
    width: 46px;
    height: 46px;
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    /* background: rgba(234, 181, 20, 1); */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 39px;
    left: 0;
}


.footer_row .img_div img{
   filter: drop-shadow(1px 2px 5px #000000c4);
}

.footer_row .contact_links .email_footer {
    margin-top: 17px;
}

.footer_row .contact_links .email_footer .img_div{
    top: 113px;
}


.footer_row ul li a ,.footer_row p{
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
    margin-bottom: 0;
    color: rgba(252, 245, 224, 1);
    word-break: break-word;

}

.footer_row .loaction_icon{
    font-size: 21px;
    display: none;
}
/* -------------------------------------------------------  follow_us  --------------------------------------------------------- */

.follow_us_row{
width: 100%;
}
.follow_us{
    padding-bottom: 40px;
    text-align: center;
}

.follow_us h2{
    margin-top: 50px;
    color: rgba(255, 255, 255, 1);
    font-size: 28px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.follow_us ul{
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
}

.follow_us ul li a{
    width: 60px;
    height: 60px;
    /* background-color: rgba(234, 181, 20, 1); */
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    display: inline-block;
    border-radius: 50%;
    margin: 24px 20px 0px;
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follow_us ul li a svg {
    filter: drop-shadow(1px 2px 5px #000000c4);
}



/* ----------------------------------------------------------  media querry  -------------------------------------------- */

@media(max-width:1030px){
    .footer_content_top .company_description{
        width: 88%;
    }
}

@media(max-width:992px){
    
    .footer_row ul li a, .footer_row p{
        font-size: 16px;
    }

    .footer_row .address_links, .footer_row .contact_links {
        position: relative;
        padding-left: 50px;
    }

    .footer_row .address_links .img_div, .footer_row .contact_links .img_div {
        width: 40px;
        height: 40px;

    }
}

@media(max-width:767px){
    .footer_content_top .company_description {
        width: 90%;
        margin-left: 75px;
        margin-top: 50px;
    }

    .footer_row .address_links {
        position: relative;
        padding-left: 50px;
        margin-top: 20px;
    }

     .footer_row .contact_links {
        position: relative;
        padding-left: 50px;
        margin-top: 40px;
    }

    .footer_row .usefull_links{
        padding-left: 50px;
    }

    .footer_row .address_links .img_div, .footer_row .contact_links .img_div {
        left: -15px;
    }

    .footer_row {
        padding: 0px 25px 50px 45px;
        width: 100%;
    }

    .footer_row ul li a, .footer_row p{
        font-size: 18px;
    }
    
    
    .footer_row .address_links, .footer_row .contact_links {
        position: relative;
        padding-left: 50px;
    }

    .footer_row .address_links .img_div, .footer_row .contact_links .img_div {
        width: 46px;
        height: 46px;

    }
}

@media(max-width:576px){

    .footer_row .company_description {
        font-size: 22px;
        margin-bottom: 35px;
    }

    .footer_row h5 {
        font-size: 23px;
        margin-top: 26px;
    }

    .footer_row ul li a, .footer_row p {
        font-size: 17px;
    }

    .footer_content_top .company_description{
        font-size: 19px;
        margin-left: 20px;
    }
    
        
    .footer_content_top .img_div img{
        display: block;
        margin: auto;
    }

    .footer_content_top .company_description::before {
        top: -9px;
        left: -44px;
        width: 33px;
        height: 23px;
    }
    
    .footer_row ul li a, .footer_row p{
        font-size: 18px;
    }
       
    .follow_us ul li a {
        margin: 24px 18px 0px;
    }
    .follow_us h2{
        font-size: 28px;
    }  
}

@media(max-width:425px){
    
    .follow_us ul li a{
        width: 50px;
        height: 50px;
        margin: 24px 11px 0px;
        font-size: 24px;
    }
    
    .follow_us h2{
        margin-top: 40px;
    }

    .follow_us{
        padding-bottom: 30px;
    }
}