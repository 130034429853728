input:focus,textarea:focus{
    outline: none;
}

input::placeholder,textarea::placeholder{
    font-size: 20px;
}



input[type="number"]:hover::-webkit-inner-spin-button,
input[type="number"]:hover::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
}


/* ----------------------------------------------------------  Top_seciton  -------------------------------------------- */

.contact_us .Top_seciton{
    /* height: 300px; */
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px 60px 240px 60px;
    position: relative;

}

.contact_us .Top_seciton .img_div{
    position: absolute;
    top: 190px;
    width: 85%;
    left: 50%;
    transform: translate(-50%,0);
    height: 500px;
}

.contact_us .Top_seciton .img_div img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
}
/* 
.contact_us .Top_seciton{
    height: 300px;
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px;
} */

.contact_us .Top_seciton .main_title {
    color: rgba(234, 181, 20, 1);
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.contact_us .Top_seciton .breadcrumbs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}

.contact_us .Top_seciton .breadcrumbs .breadcrumbs_Arrow{
    color: rgba(234, 181, 20, 1);
    font-size: 26px;
    position: relative;
}
.contact_us .Top_seciton .breadcrumbs .breadcrumbs_Arrow:hover{
    transform: translateX(0.5rem);
}
.contact_us .Top_seciton .breadcrumbs a{
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 0px 15px;
    text-transform: uppercase;
}

/* ----------------------------------------------------------  contact_us_help_you_out  -------------------------------------------- */

.contact_us_help_you_out{
    width: 100%;
    margin: auto;
    margin-top: 400px;
    text-align: center;
}

.contact_us_help_you_out .col_main .card h5{
    color: rgba(190, 152, 19, 1);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.contact_us_help_you_out .col_main .card h2{
    color: rgba(234, 181, 20, 1);
    font-size: 31px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 35px;
}

.help_you_out_row{
    background: url(../../Assets/background_ecllipse.png);
    background-repeat: no-repeat;
    width: 100%;
    margin: auto;
    margin-top: 50px;
    align-items: center;
}

.help_you_out_row .col_main1 .img_div{
    width: 100%;
    height: 600px;
}

.help_you_out_row .col_main .img_div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

.help_you_out_row .col_main .card{
    background: transparent;
    border: none;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    text-align: left;


}

.help_you_out_row .col_main .card p{
    color: rgba(246, 245, 242, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-align: left;
    width: 94%;
    text-transform: capitalize;
}

.help_you_out_row .col_main .card a > a{
    background: rgba(234, 181, 20, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    border: none;
    padding: 10px 12px;
    /* width: max-content; */
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 0px;
    display: inline-block;
}

/* ----------------------------------------------------------  contact_us_get_in_touch  -------------------------------------------- */


.contact_us_get_in_touch{
    width: 90%;
    margin: auto;
    margin-top: 100px;
    text-align: left;
    margin-bottom: 50px;
}

.contact_us_get_in_touch .content{
    text-align: left;
    width: 50%;
}

.contact_us_get_in_touch .content h5{
    color: rgba(234, 181, 20, 1);
    font-size: 29px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.contact_us_get_in_touch .content p{
    color: rgba(234, 181, 20, 1);
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 28px;
    width: 98%;
    margin-top: 20px;
}

.contact_us_get_in_touch .col_main1 .register_form{
    margin-top: 10px;
    margin-bottom: 70px;
}

.contact_us_get_in_touch .col_main1 .register_form .input_div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.contact_us_get_in_touch .col_main1 .register_form .input_div label{
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.contact_us_get_in_touch .col_main1 .register_form .input_div input , .contact_us_get_in_touch .col_main1 .register_form .input_div textarea{
    color: rgba(255, 255, 255, 1);
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border: 2px solid rgba(234, 181, 20, 1);
    border-radius: 12px;
    background: transparent;
    padding: 2px 17px;
    width: 86%;
    height: 47px;
    margin-top: 6px;
}

.contact_us_get_in_touch .col_main1 .register_form .input_div textarea{
    height: 150px;
    resize: none;
    padding: 10px 15px;
}

.contact_us_get_in_touch .col_main1 .register_form button{
    background: rgba(234, 181, 20, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    border: none;
    padding: 9px 11px;
    width: max-content;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 0px;
}


.contact_us_get_in_touch .col_main2 .col_row2{
    width: 96%;
    margin: auto;
    margin-top: 0px;
    justify-content: center;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card{
    background: linear-gradient(rgba(248, 216, 118, 1),rgba(203, 154, 5, 1));
    border-radius: 15px;
    border: none;
    padding: 26px 30px;
    height: 210px;
    width: 94%;
    margin-bottom: 53px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card .img_div{
    width: 50px;
    height: 50px;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card .img_div .svf_images{
    width: 100%;
    height: 100%;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card .img_div .svf_images path{
    fill: rgba(57, 53, 53, 1);
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card h6, .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card p{
    color: rgba(57, 53, 53, 1);
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card p{
    font-size: 15px;
    margin-top: 10px;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card button{
    border: none;
    background: rgba(57, 53, 53, 1);
    color: white;
    width: 125px;
    padding: 4px 27px;
    border-radius: 20px;
    margin-top: auto;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card h6{
    margin-top: 12px;
    line-height: 27px;
    margin-bottom: 0;
}

.contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card .email_content{
    width: 100%;
    margin-top: 0px;
}





/* ----------------------------------------------------------  media querry  -------------------------------------------- */


/* @media(max-width:757px){
    .contact_us .Top_seciton {
        padding: 49px 0;
    }
} */

@media(min-width:1600px){
    .contact_us .Top_seciton .img_div img{
        width: 85%;
    }
}

@media(max-width:1200px){
    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card{
        width: 100%;
        margin-bottom: 29px;
        height: 240px;
    }

    .help_you_out_row .col_main .img_div img{
        object-position: center;
    }
}

@media(max-width:1000px){
    
    .contact_us .Top_seciton .img_div {
        width: 90%;
        height: 400px;
    }
    
    .contact_us .Top_seciton .img_div img {
        width: 100%;
    }

    .contact_us_help_you_out {
        margin-top: 290px;
    }

}

@media(max-width:991px){

    /* .contact_us_help_you_out {
        margin-top: 430px;
    } */

    .help_you_out_row{
        width: 100%;
    }

    .contact_us_get_in_touch .col_main2 .col_row2{
        width: 100%;
    }

    .contact_us_get_in_touch .content {
        width: 100%;
    }
    
    .help_you_out_row .col_main1 .img_div {
        width: 150%;
    }
    
    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card{
        height: 260px;
    }
}

@media(max-width:767px){

    .help_you_out_row .col_main1 {
        overflow: hidden;
    }

    .contact_us .Top_seciton {
        padding: 69px 0px 203px 0px;
    }

    .contact_us .Top_seciton .main_title{
        font-size: 30px;
    }

    .contact_us .Top_seciton .breadcrumbs a{
        font-size: 18px;
    }

    .help_you_out_row .col_main .img_div img {
        object-fit: cover;
    }

    .help_you_out_row .col_main1 .img_div {
        height: 500px;
        width: 149%;
    }
    
    .help_you_out_row {
        width: 100%;
        background-size: cover;
        background-repeat: round;
        padding: 39px 10px;
    }

    .contact_us_get_in_touch .content{
        width: 85%;
    }

    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card {
        width: 100%;
        margin-bottom: 30px;
        height: 232px;
    }

    .contact_us_get_in_touch .col_main2 .col_row2 {
        justify-content: center;
        margin-top: 10px;
    }

    .contact_us_get_in_touch .col_main1 .register_form{
        margin-bottom: 60px;
    }

    .contact_us_get_in_touch {
        margin-bottom: 70px;
    }
        
    .contact_us_help_you_out .col_main .card h5{
        font-size: 20px;
    }
        
    .contact_us_help_you_out .col_main .card h2{
        font-size: 27px;
        margin-bottom: 20px;
    }
        
    .help_you_out_row .col_main .card p{
        font-size: 17px;
    }
}

@media(max-width:575px){

    .contact_us .Top_seciton .img_div {
        width: 90%;
        height: 300px;
        top: 196px;
    }

    .contact_us_help_you_out {
        margin-top: 230px;
    }

    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card p{
        font-size: 16px;
    }

    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card {
        margin: auto;
        margin-bottom: 30px;
        height: 190px;
    }

    .contact_us_get_in_touch .col_main1 .register_form .input_div input, .contact_us_get_in_touch .col_main1 .register_form .input_div textarea {
        width: 90%;
    }
    
    .help_you_out_row .col_main .card{
        margin-top: 70px;
    }

    
    .help_you_out_row .col_main1 .img_div {
        width: 136%;
    }

    
    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card {
        height: auto;
    }

    .contact_us_get_in_touch .col_main2 .col_row2 .col_row2_main .card button {
        margin-top: 20px;
    }

}
