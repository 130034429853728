
/* ----------------------------------------------------------  Top_seciton  -------------------------------------------- */

.about_us .Top_seciton{
    /* height: 300px; */
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px 60px 240px 60px;
    position: relative;

}

.about_us .Top_seciton .img_div{
    position: absolute;
    top: 190px;
    width: 85%;
    left: 50%;
    transform: translate(-50%,0);
    height: 500px;
}

.about_us .Top_seciton .img_div img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
}

.about_us .Top_seciton .main_title {
    color: rgba(234, 181, 20, 1);
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.about_us .Top_seciton .breadcrumbs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}

.about_us .Top_seciton .breadcrumbs .breadcrumbs_Arrow{
    color: rgba(234, 181, 20, 1);
    font-size: 26px;
    position: relative;
    cursor: pointer;
}

.about_us .Top_seciton .breadcrumbs .breadcrumbs_Arrow:hover{
    transform: translateX(0.5rem);
}

.about_us .Top_seciton .breadcrumbs a{
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 0px 15px;
    text-transform: uppercase;
}


/* ----------------------------------------------------------  about_smart_solution  -------------------------------------------- */

.about_us .about_smart_solution{
    margin-top: 400px;
    text-align: center;
}

.about_us .about_smart_solution h6{
    color: rgba(190, 152, 19, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    margin-bottom: 5px;
}

.about_us .about_smart_solution h2{
    color: rgba(190, 152, 19, 1);
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
}

.about_us .about_smart_solution .about_smart_solution_row{
    width: 89%;
    margin: auto;
    margin-top: 70px;
}

.about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div{
    width: 98%;
    position: relative;
    height: 100%;
}

.about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-bottom-left-radius: 75px;
}

.about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div .our_vission{
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    bottom: 0;
    right: 0;
    padding: 53px 28px 63px;
    text-align: left;
    width: 100%;
    border-bottom-left-radius: 77px;
}

.about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div .our_vission h6{
    color: rgb(229 177 20);
    font-size: 23px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div .our_vission p{
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
}

.about_us .about_smart_solution_row .col_main_2 .right_side_img {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
    position: relative;
}

.about_us .about_smart_solution_row .col_main_2 .right_side_img .img_div{
    width: 100%;
    height: 50%;
    border-radius: 10px;
}

.about_us .about_smart_solution_row .col_main_2 .right_side_img .img_div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 13px;
}

.about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion{
    background-color: rgba(229, 177, 20, 1);
    padding: 20px;
    /* margin-top: 48px; */
    height: 44%;
    border-bottom-right-radius: 70px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion h6{
    color: rgb(57 53 53);
    font-size: 23px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion p{
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}



/* ----------------------------------------------------------  about_our_values  -------------------------------------------- */


.about_our_values{
    width: 93%;
    margin: auto;
    margin-top: 70px;
    text-align: center;
}

.about_our_values > h5{
    color: rgba(252, 245, 224, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.about_our_values > h2{
    color: rgba(252, 245, 224, 1);
    font-size: 34px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 20px;
    /* line-height: 50px; */
    text-transform: capitalize;
}

.about_our_values .about_our_values_row{
    width: 100%;
    margin: auto;
    margin-top: 30px;
    justify-content: center;
}

.about_our_values .about_our_values_row .col_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.about_our_values .about_our_values_row .col_main .img_div{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: rgba(234, 181, 20, 1);
    display: flex;
    align-items: center;
    justify-content: center;

}

.about_our_values .about_our_values_row .col_main h5{
    color: rgba(243, 179, 23, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 20px 0px;
}

.about_our_values .about_our_values_row .col_main p{
    color: rgba(246, 245, 242, 1);
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    width: 90%;
    line-height: 27px;
    text-transform: capitalize;
    /* text-align: justify; */
    /* word-break: break-all; */
}


/* ----------------------------------------------------------  about_get_started  -------------------------------------------- */


.about_get_started {
    /* padding: 55px; */
    text-align: center;
    margin-top: 100px;
    position: relative; 
    height: 455px;
    background: #fbe5ca;
}


.about_get_started img{
    width: 100% !important;
    height: 100%;
    object-fit: cover;
}

.get_started_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 85%;
}

.get_started_content h4{
    color: rgba(240, 73, 21, 1);
    font-size: 23px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.get_started_content h2{
    color: rgba(234, 181, 20, 1);
    font-size: 36px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 10px 0px;
    /* line-height: 60px; */
}

.get_started_content p{
    color: rgba(96, 53, 73, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    width: 100%;
    margin: auto;
    line-height: 36px;
    text-transform: capitalize;
}

.about_get_started button{
    background: rgba(234, 181, 20, 1);
    border-radius: 6px;
    border: none;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    padding: 14px 22px;
    margin-top: 30px;
}




/* ----------------------------------------------------------  about_our_process  -------------------------------------------- */


.about_our_process{
    width: 100%;
    margin: auto;
    margin-top: 100px;
    text-align: center;
}

.about_our_process > h6{
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.about_our_process > h2{
    color: rgba(255, 255, 255, 1);
    font-size: 31px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 50px;
    margin-top: 10px;
}

.about_our_process .about_our_process_row{
    width: 96%;
    margin: auto;
    margin-top: 20px;
    justify-content: center;
}

.about_our_process .about_our_process_row .col_main{
    padding: 0;
    margin-top: 30px;
}

.about_our_process .about_our_process_row .col_main .card{
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    border: none;
}

/* .about_our_process .about_our_process_row .col_main:last-child .card::before{
    display: none;
}

.about_our_process .about_our_process_row .col_main .card::before{
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    left: 0;
    top: 30px;
    z-index: 2;
} */

.about_our_process .about_our_process_row .col_main .card .img_div{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    margin: auto;
}

.about_our_process .about_our_process_row .col_main .card .img_div img{
    width: 100%;
    height: 100%;
}

.about_our_process .about_our_process_row .col_main .card .img_div span{
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: rgba(234, 181, 20, 1);
    color: rgba(57, 53, 53, 1);
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    top: 54px;
    position: absolute;
    right: -25px;
}

.about_our_process .about_our_process_row .col_main .card h6{
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    margin: 20px 0px 10px;
}

.about_our_process .about_our_process_row .col_main .card p{
    color: rgba(248, 242, 242, 1);
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    width: 90%;
    text-align: center;
    margin: auto;
    text-transform: capitalize;
}


/* ----------------------------------------------------------  about_our_team  -------------------------------------------- */

.about_our_team {
    margin-top: 100px;
    text-align: center;
}

.about_our_team > h5{
    color: rgba(255, 255, 255, 1);
    font-size: 19px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.about_our_team > h2{
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 12px;
}

.about_our_team .about_our_team_row{
    width: 92%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 70px;
    justify-content: center;
}

.about_our_team .about_our_team_row .col_main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 35px;
}

.about_our_team .about_our_team_row .col_main .img_div{
    width: 80%;
    margin: auto;
}

.about_our_team .about_our_team_row .col_main .img_div img{
    width: 100%;
}


.about_our_team .about_our_team_row .col_main h5 {
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.about_our_team .about_our_team_row .col_main p {
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: 22px 0px 10px 0px;
}


/* ----------------------------------------------------------  media querry  -------------------------------------------- */

@media(min-width:1800px) and (max-width:4000px){
    .about_our_team .about_our_team_row .col_main .img_div{
        width: 60%;
    }
}

@media(min-width:1600px){
    .about_us .Top_seciton .img_div img{
        width: 85%;
    }
}

@media(max-width:1000px){
    
    .about_us .Top_seciton .img_div {
        width: 90%;
        height: 400px;
    }

    .about_us .about_smart_solution {
        margin-top: 290px;
    }

    
    .about_us .Top_seciton .img_div img {
        width: 100%;
    }
}

@media(max-width:991px){
    .about_our_process .about_our_process_row .col_main .card::before{
        display: none;
    }

    .about_get_started{
        height: 400px;
    }

    .get_started_content{
        width: 96%;
    }

    .get_started_content p{
        width: 95%;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .img_div {
        height: 41%;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion {
        padding: 20px;
        height: 53%;
    }

    
    


}


@media(max-width:767.98px){
    .about_our_team .about_our_team_row .col_main .img_div {
        width: 90%;
        margin: auto;
    }    

    .about_our_team > h5,.about_our_process > h6{
        font-size: 22px;
    }

    .about_our_team > h2,.about_our_process > h2 {
        font-size: 31px;
    }

    .about_our_process > h2{
        width: 97%;
        margin: auto;
    }

    .about_our_process .about_our_process_row .col_main .card p{
        font-size: 16px;
        width: 96%;
    }

    .about_our_process .about_our_process_row .col_main .card h6{
        font-size: 18px;
    }

    .about_our_process .about_our_process_row{
        width: 90%;
    }

    .get_started_content h2{
        font-size: 35px;
    }

    .get_started_content h4{
        font-size: 28px;
    }

    .get_started_content p{
        font-size: 22px;
    }

    .about_our_values > h2{
        font-size: 27px;
    }

    .about_our_values .about_our_values_row .col_main p{
        width: 100%;
    }

    .about_us .about_smart_solution_row .col_main_2{
        margin-top: 50px;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img{
        flex-direction: row;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .img_div {
        height: 100%;
        width: 44%;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion {
        padding: 26px 15px;
        height: 100%;
        width: 50%;
    }

    .about_us .about_smart_solution .about_smart_solution_row .col_main_1{
        height: max-content;
    }

    .about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div {
        height: 85%;
    }

    .about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div .our_vission {
        padding: 20px;
    }

    .about_us .about_smart_solution h2{
        font-size: 25px;
    }

    .about_us .about_smart_solution h6{
        font-size: 18px;
    }

    .about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div .our_vission h6{
        font-size: 25px;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion h6{
        font-size: 25px;
    }

    .about_us .Top_seciton {
        padding: 69px 0px 203px 0px;
    }

    .about_us .Top_seciton .main_title{
        font-size: 30px;
    }

    .about_us .Top_seciton .breadcrumbs a{
        font-size: 18px;
    }

    .about_get_started {
        height: 480px;
    }

    .about_get_started img {
        object-fit: cover;
    }

    .about_our_values .about_our_values_row .col_main .img_div {
        width: 75px;
        height: 75px;
    }

    .about_our_values > h5 {
        font-size: 18px;
    }
}

@media(max-width:575px){
    .about_our_team .about_our_team_row .col_main .img_div {
        width: 70%;
        margin: auto;
    }    

    .about_our_team > h5,.about_our_process > h6{
        font-size: 20px;
        line-height: 32px;
        margin-top: 14px;
    
    }

    .about_our_team > h2 ,.about_our_process > h2{
        font-size: 27px;
    }

    .about_our_process .about_our_process_row{
        width: 89%;
    }

    .about_our_process .about_our_process_row .col_main .card p{
        width: 100%;
    }

    .about_get_started img{
        display: none;
    }
    
    .about_get_started{
        height: auto;
        /* background-color: rgba(251, 229, 202, 1); */
        position: relative;
        background: url(../../Assets/get_started.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .get_started_content{
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        padding: 71px 13px;
    }

    .get_started_content p{
        width: 95%;
    font-size: 18px;
    line-height: 33px;
    }

    .get_started_content h4{
        font-size: 25px;
    }
    .get_started_content h2{
        font-size: 32px;
        line-height: 48px;
    }
    
    .about_get_started button {
        font-size: 16px;
        padding: 10px 20px;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img{
        flex-direction: column;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .img_div {
        height: auto;
        width: 100%;
    }

    .about_us .about_smart_solution_row .col_main_2 .right_side_img .our_mimssion {
        padding: 15px;
        height: auto;
        width: 100%;
        margin-top: 50px;
    }

    .about_us .about_smart_solution .about_smart_solution_row .col_main_1 .img_div {
        height: 375px;
    }

    .about_us .Top_seciton .img_div {
        width: 90%;
        height: 300px;
        top: 196px;
    }

    .about_us .about_smart_solution {
        width: 95%;
        margin: auto;
        margin-top: 230px;
    }

    .about_our_values .about_our_values_row .col_main p {
        width: 100%;
        text-align: center;
        /* word-break: break-all; */
    }
    
    .about_our_values .about_our_values_row {
        margin-top: 15px;
    }

    .about_our_process{
        width: 95%;
    }
    

    .about_our_process .about_our_process_row .col_main .card .img_div {
        width: 135px;
        height: 135px;
    }
    
    .about_our_process .about_our_process_row .col_main .card .img_div span {
        width: 50px;
        height: 50px;
        font-size: 19px;
    }

    .about_our_team{
        width: 95%;
        margin: auto;
        margin-top: 70px;
    }
}