.header{
    padding: 6px 16px;
    overflow-x: hidden;
}

.header .nav_brand{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 80px; */
}

.header .nav_brand img{
    height: 53px;
}

.header .logo_name{
    font-family: 'Nova Round', system-ui;
    font-size: 11px;
    color: #EAB514;
    margin-top: 3px;
}

.header .main_menu {
    justify-content: flex-end !important;
    margin-left: auto;
    align-items: center;
    margin-right: 6px;
}

.header .main_menu .nav-link.active, .header .main_menu .nav-link.show{
    color: #EAB514 !important;
}

.header .main_menu > a > a{
    color: #EAB514;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0px 20px 0px 0px;
}

.header .main_menu > a > a .home_icon{
    position: relative;
    bottom: 0px;
    fill: transparent ;
}

.contact_us_header{
    /* background-color: #EAB514 !important; */
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%) !important;
    color: white !important;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 9px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
}

.contact_us_header{
    border: 2px solid #eab514;
    color: #eab514;
    /* margin-top: 12px;     */
} 

.contact_us_header:hover{
    border: 2px solid #eab514;
    background: #eab514;
    color: white !important;
    
} 

.header .main_menu > a > a.active{
    color: rgba(217, 217, 217, 1);
    position: relative;
    display: inline-block;
}

.header .main_menu > a > a.active .home_icon path{
    stroke: rgba(217, 217, 217, 1);
    fill: transparent;
}


.header .main_menu > a > a.active::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 31px;
    width: 84%;
    height: 2px;
    border-radius: 10px;
    background: rgba(217, 217, 217, 1);
    position: relative;
    display: block;
    transform: translate(-50%,0);
}

.header .main_menu > a:first-child > a.active::before{
    display: none;
}


/* ----------------------------------------------------------  media querry  -------------------------------------------- */



/* @media (max-width:1400px){
    .header .main_menu {
        max-width: 71%;
    }    
} */

@media (max-width:1200px){

    .header .nav_brand{
        width: 185px;
    }

    /* .header .main_menu > a > a{
        font-size: 14px;
    } */

    .header .logo_name{
        font-size: 12px;
    }

    .header .nav_brand img{
        width: 100%;
    }

    /* .header .main_menu {
        max-width: 77%;
    }     */

    .contact_us_header {
        padding: 8px 14px;
        font-size: 14px;
    }

    .header .main_menu > a > a{
        font-size: 16px;
        margin: 0px 7px 0px 0px;
    }
}

@media (max-width:992px){
    .header {
        padding: 1px 24px;
        padding-right: 10px;
    }

    .header .nav_brand{
        width: 145px;
    }

}

@media (max-width:992px){
    .header {
        padding: 4px 10px 5px;
        padding-right: 5px;
        /* margin-bottom: 20px; */
    }

    .header .nav_brand{
        width: 141px;
    }

    .header .main_menu > a > a{
        margin: 0px 7px 0px 0px;
    }

    .header .logo_name {
        font-size: 10px;
    }

    .header .main_menu{
        padding-right: 0px !important;
    }

}

@media (max-width:805px){
    
    .header .main_menu > a > a{
        margin: 0px 0px 0px 0px;
    }

}

@media(max-width:767.98px){

    .header {
        padding: 4px 10px 5px;
        padding-right: 5px;
        /* padding-left: 20px; */
     
    }
    

    .contact_us_header{
        border: 2px solid #eab514;
        color: #eab514;
        margin-top: 12px;
    } 

    .contact_us_header:hover{
        border: 2px solid #eab514;
        background: #eab514;
        color: white !important;
        
    } 

    .offcanvas-header .btn-close {
        margin-left: auto;
    }

    .offcanvas-body .nav-link a{
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        color: rgba(234, 181, 20, 1);
    }

    .navbar-toggler:focus,.navbar-toggler{
        outline: none;
        border: none;
        color: transparent;
    }

    .navbar-toggler{
        position: relative;
        border: none;
    }

    .navbar-toggler::before{
        content: "";
        position: absolute;
        background: #f8b715;
        height: 2px;
        width: 47%;
        left: 50%;
        transform: translate(-50%,0);
        top: 10px;
        z-index: 10;
    }

    .navbar-toggler::after{
        content: "";
        position: absolute;
        background: #f8b715;
        height: 2px;
        width: 47%;
        left: 50%;
        transform: translate(-50%,0);
        bottom: 10px;
        z-index: 10;
    }

    .navbar-toggler span{
        position: relative;
    }

    .navbar-toggler span::before{
        content: "";
        position: absolute;
        background: #f8b715;
        height: 2px;
        width: 80%;
        left: 50%;
        transform: translate(-50%,0);
        bottom: 14px;
    }


    /* .navbar-toggler{
        border: none;
    }

    .navbar-toggler span{
        background: url(../../Assets/toggle_icon.svg);
        background-size: cover;
        width: 40px;
        height: 40px;
    } */

   

    .navbar > .container-fluid {
        padding: 0;
    }

    .header .logo_name {
        font-size: 8px;
    }
    .header .nav_brand img {
        height: 33px;
    }

.offcanvas-body .nav-link a.active{
        color: #393535;
        position: relative;
        display: inline-block;
    }
    
.offcanvas-body .nav-link a.active .home_icon path{
        stroke: #393535;
        fill: transparent;
    }
}

@media(max-width:576px){
 
    .offcanvas.offcanvas-end.show{
        width: 65%;
        /* max-width: 65%; */
    }

    .offcanvas.offcanvas-end{
        /* max-width: 65%; */
        /* transform: translateX(65%); */
        transform: translateX(100%);
        transition: transform 0.5s ease-out;

    }

}
