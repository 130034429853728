.my-container {
    padding: 0 !important;
}

.smart_Solution {
    position: relative;
    background: url('../../Assets/top_section_background.svg');
    background-size: cover;
    height: 1000px;
    background-repeat: no-repeat;
    /* display: flex; */
    z-index: 10;
}

.smart_Solution::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #00000085;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.smart_Solution_row {
    /* background-color: rgba(243, 237, 230, 1); */
    /* background: url('../../Assets/top_section_background.svg');
    background-size: cover; */
    /* align-items: center; */
    padding: 0px 30px;
    /* padding-bottom: 150px; */
    padding-top: 40px;
    /* padding-bottom: 413px; */
    margin-bottom: 75px;
    align-self: center;
    z-index: 10;
}

.smart_Solution_row2 {
    /* background-color: rgba(243, 237, 230, 1); */
    align-items: center;
    padding: 0px 30px;
    padding-top: 0px;
}

.smart_Solution_row .col_main_1 {
    padding-top: 37px;
}

.smart_Solution_row .col_main_1 h6,
.smart_Solution_row .col_main_1 .typeAnimationsh6 {
    color: rgba(190, 152, 19, 1);
    font-size: 23px;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    height: max-content !important;
    background: linear-gradient(89.53deg, #ECC475 0.17%, #E2AF49 99.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
}

.smart_Solution_row .col_main_1 h2,
.smart_Solution_row .col_main_1 .typeAnimationsh2 {
    color: rgba(190, 152, 19, 1);
    font-size: 39px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    height: max-content !important;
    background: linear-gradient(85deg, #D6A445 0%, #E6C483 20.09%, #FFCD6D 61.15%, #C6A25C 78.01%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
}

.smart_Solution_row .col_main_1 p {
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    width: 90%;
    text-transform: capitalize;
    margin-top: 20px;
}

.smart_Solution_row .col_main_1 button {
    /* background-color: rgba(234, 181, 20, 1); */
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    font-size: 16px;
    color: rgba(57, 53, 53, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 9px 17px;
    border-radius: 5px;
    margin-top: 15px;
    z-index: 10;
    position: relative;
}

.smart_Solution_row img {
    /* width: 100%;
    margin: auto;
    display: block;
     height: 429px; 
    margin-top: 30px; */

    width: 580px;
    margin: auto;
    display: block;
    height: 421px;
    margin-top: 0;
    object-fit: cover;
    display: none;

}

.top_center_card_row {
    width: 100%;
    align-items: center;
    position: absolute;
    bottom: -90px;
    left: 0;
    right: 0;
    margin: auto;
}

.top_center_card_row .our_vision_img {
    width: 67px;
    height: auto !important;
    position: absolute;
    top: 32px;
    left: 66px;
}

.top_center_card_row .our_vision_span {
    position: relative;
    left: 10px;
}

.top_center_card_row .our_mission_span {
    position: relative;
    left: 10px;
}

.top_center_card_row .our_mission_img {
    width: 77px;
    height: auto !important;
    position: absolute;
    top: 24px;
    left: 73px;
}

.top_card_main {
    display: flex;
    /* position: relative; */
}

.top_card_main .card {
    height: 440px;
    background: transparent;
    border: none;
}

.top_card_main .card img {
    height: 100%;
}

.top_card_main .card1 {
    background: url(../../Assets/top_section_card_img.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    margin: auto;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

.top_card_main .card1::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #00000026;
    z-index: 10;
    top: 0;
    left: 0;
}

.parallel_card,
.parallel_card>div {
    border-radius: 20px;
    height: max-content;
    margin-bottom: 0;
}

.top_card_main .card2,
.top_card_main .card3 {
    height: 410px;
    /* background: url(../../Assets/card_background.png); */
    /* background:  rgba(251, 229, 202, 1); */
    background: linear-gradient(231deg, #FFF -11.74%, rgba(255, 255, 255, 0.00) 137.62%), #FACD44;
    background-size: cover;
    background-repeat: repeat;
    /* width: 90%; */
    width: 90%;
    /* for parallel efects */
    margin: auto;
    padding: 46px 44px 45px 47px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s linear;
}

.top_card_main .card2:hover,
.top_card_main .card3:hover {
    transform: scale(1.05);
    transition: all 0.3s linear;
}

.top_card_main .card2::before,
.top_card_main .card3::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
    height: 100px;
    background: url('../../Assets/our_vission_top_img.svg');
}

.top_card_main .card2::after,
.top_card_main .card3::after {
    content: "";
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 135px;
    height: 120px;
    background: url('../../Assets/our_vission_bottom_img.svg');
    z-index: 20;
    background-size: cover;
    background-position: bottom left;
}

.top_card_main .card2 p::before {
    content: "";
    position: absolute;
    top: 54px;
    right: 13px;
    width: 114px;
    height: 163px;
    background: url('../../Assets/our_vission_right_img.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.top_card_main .card3 p::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 13px;
    width: 130px;
    height: 130px;
    background: url('../../Assets/our_mission_right_img.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.top_card_main .card2 h5,
.top_card_main .card3 h5 {
    color: rgba(0, 0, 0, 1);
    font-size: 31px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-top: 20px;
    z-index: 10;
}

.top_card_main .card2 p,
.top_card_main .card3 p {
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin-top: 15px;
    z-index: 10;
    width: 100%;
}




/* -------------------------------------------------------  What_we_do  --------------------------------------------------------- */

.What_we_do {
    /* background-color: #87cfeb00; */
    padding-top: 240px;
    background: url("../../Assets/whatwedo_backimage.svg");
    /* background-repeat: no-repeat; */
    position: relative;
    background-size: cover;
    z-index: 5;
    padding-bottom: 100px;
}


.What_we_do::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #00000085;
    width: 100%;
    height: 100%;
    z-index: 10;
}


.What_we_do_row {
    width: 86% !important;
    margin: auto;
    margin-top: 40px;
    z-index: 20;
    position: relative;
}


.What_we_do .col_main h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 65px;
    text-align: left;
    text-transform: uppercase;
    background: linear-gradient(89.46deg, #D6A445 5.94%, #E6C483 25.59%, #FFCD6D 72.49%, #C6A25C 91.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
    width: 95%;
}

.What_we_do_row .col_main p {
    color: rgba(252, 245, 224, 1);
    font-size: 23px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    margin-bottom: 60px;
    line-height: 35px;
    width: 90%;
}

.What_we_do_row .col_main ul {
    /* padding: 0; */
    list-style: none;
    margin-top: 35px;
    padding-left: 40px;
}

.What_we_do_row .col_main ul li {
    color: rgba(252, 245, 224, 1);
    font-size: 23px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    position: relative;
    margin-bottom: 10px;
}

.What_we_do_row .col_main ul li::before {
    content: url(../../Assets/list_symbol.svg);
    position: absolute;
    top: 3px;
    left: -41px;
}

.What_we_do_row .col_main_1 {
    padding-bottom: 70px;
    padding-bottom: 70px;
}

.What_we_do_row .Know_more {
    background-color: rgba(234, 181, 20, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 9px 17px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.what_we_do_grid {
    display: grid;
    grid-template-columns: 50% 50%;
    /* grid-template-rows: auto auto; */
    /* gap: 20px; */
    width: 100%;
}

.what_we_do_grid .what_we_do_grid_item1 {
    grid-column: 1 / span 2;
    position: relative;
    overflow: hidden;
    border-radius: 13px;
    margin-bottom: 20px;
}

.what_we_do_grid .what_we_do_grid_item1::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    background: #00000033;
    width: 99%;
    height: 98%;
    z-index: 10;
    transform: translate(-50%, -50%);
    border-radius: 13px;
}

.what_we_do_grid .what_we_do_grid_item2>.img_div {
    position: relative;
    border: 3px solid white;
    border-radius: 13px;
    overflow: hidden;
    margin-right: 10px;
}

.what_we_do_grid .what_we_do_grid_item3 .item3_main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 10px;
}

.what_we_do_grid .what_we_do_grid_item3 .item3_main>.img_div {
    position: relative;
    border: 3px solid white;
    border-radius: 13px;
    overflow: hidden;
}

.what_we_do_grid .what_we_do_grid_item2>.img_div::before,
.what_we_do_grid .what_we_do_grid_item3 .item3_main>.img_div::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.what_we_do_grid .what_we_do_grid_item3 .item3_main>span {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 900;
    /* line-height: 65px; */
    text-align: left;
    text-transform: capitalize;
    background: linear-gradient(89.73deg, #ECC67D 0.07%, #D6A445 99.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);

}


.parallel_video_card {
    width: 92%;
    height: 370px;
}

.What_we_do_row .col_main_2 .video_div {
    width: 100%;
    height: 305px;
    background-color: rgba(255, 255, 255, 1);
    /* border-top-right-radius: 100px; */
    /* border-bottom-left-radius: 100px; */
    margin: auto;
    border: 3px solid rgba(255, 255, 255, 1);
    overflow: hidden;
    position: relative;
    border-radius: 13px;
}

/* .What_we_do_row .col_main_2 .video_div::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000009e;
    z-index: 10;
} */

.What_we_do_row .col_main_2 {
    position: relative;
}

.What_we_do_row .col_main_2 button {
    position: absolute;
    z-index: 10;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: none;
    background: linear-gradient(205.24deg, #E5AE06 10.35%, #F8C768 50.25%, #C89A45 89.87%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: rgba(248, 248, 248, 1);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 21px;
}


.What_we_do_row .col_main_2 .video_div #singleVideo {
    margin: auto;
    display: block;
    position: relative;
    top: -1px;
    overflow: hidden;
    object-fit: cover;
}



/* -------------------------------------------------------  expertice  --------------------------------------------------------- */

.expertice {
    /* background-color: #87cfeb00; */
    padding-top: 125px;
    background: url("../../Assets/expertise_background_image.svg");
    /* background-repeat: no-repeat; */
    position: relative;
    background-size: cover;
    z-index: 5;
    padding-bottom: 125px;
}


.expertice::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #00000085;
    width: 100%;
    height: 100%;
    z-index: 10;
}


.expertice_row {
    width: 86% !important;
    margin: auto;
    margin-top: 40px;
    z-index: 20;
    position: relative;
}


.expertice .col_main h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 65px;
    text-align: left;
    text-transform: uppercase;
    background: linear-gradient(89.46deg, #D6A445 5.94%, #E6C483 25.59%, #FFCD6D 72.49%, #C6A25C 91.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
    width: 95%;
}

.expertice_row .col_main p {
    color: rgba(252, 245, 224, 1);
    font-size: 23px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 35px;
    width: 90%;
}

.expertice_row .col_main ul {
    /* padding: 0; */
    list-style: none;
    margin-top: 35px;
    padding-left: 40px;
}

.expertice_row .col_main ul li {
    color: rgba(252, 245, 224, 1);
    font-size: 23px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    position: relative;
    margin-bottom: 10px;
}

.expertice_row .col_main ul li::before {
    content: url(../../Assets/list_symbol.svg);
    position: absolute;
    top: 3px;
    left: -41px;
}

.expertice_row .col_main_2 {
    padding-bottom: 70px;
    padding-left: 65px;
}

.expertice_row .Know_more {
    background-color: rgba(234, 181, 20, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 9px 17px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.expertice_grid {
    display: grid;
    grid-template-columns: 50% 50%;
    /* grid-template-rows: auto auto; */
    /* gap: 20px; */
    width: 100%;
}

.expertice_grid .expertice_grid_item1 {
    grid-column: 1 / span 2;
    position: relative;
    overflow: hidden;
    border-radius: 13px;
    margin-bottom: 20px;
}

.expertice_grid .expertice_grid_item1::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    background: #00000033;
    width: 99%;
    height: 98%;
    z-index: 10;
    transform: translate(-50%, -50%);
    border-radius: 13px;
}

.expertice_grid .expertice_grid_item2>.img_div {
    position: relative;
    border: 3px solid white;
    border-radius: 13px;
    overflow: hidden;
    margin-right: 10px;
    height: 302px;
}

.expertice_grid .expertice_grid_item3 .item3_main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 10px;
}

.expertice_grid .expertice_grid_item3 .item3_main>.img_div {
    position: relative;
    border: 3px solid white;
    border-radius: 13px;
    overflow: hidden;
    height: 141px;
}

.expertice_grid .expertice_grid_item2>.img_div::before,
.expertice_grid .expertice_grid_item3 .item3_main>.img_div::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.expertice_grid .expertice_grid_item3 .item3_main>span {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-weight: 900;
    /* line-height: 65px; */
    text-align: left;
    text-transform: capitalize;
    background: linear-gradient(89.73deg, #ECC67D 0.07%, #D6A445 99.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);

}


.parallel_video_card {
    width: 92%;
    height: 370px;
}

.expertice_row .col_main_1 .video_div {
    width: 100%;
    height: 305px;
    background-color: rgba(255, 255, 255, 1);
    /* border-top-right-radius: 100px; */
    /* border-bottom-left-radius: 100px; */
    margin: auto;
    border: 3px solid rgba(255, 255, 255, 1);
    overflow: hidden;
    position: relative;
    border-radius: 13px;
}

/* .expertice_row .col_main_1 .video_div::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000009e;
    z-index: 10;
} */

.expertice_row .col_main_1 {
    position: relative;
}

.expertice_row .col_main_1 button {
    position: absolute;
    z-index: 10;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: none;
    background: linear-gradient(205.24deg, #E5AE06 10.35%, #F8C768 50.25%, #C89A45 89.87%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: rgba(248, 248, 248, 1);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 21px;
}


.expertice_row .col_main_1 .video_div #singleVideo {
    margin: auto;
    display: block;
    position: relative;
    top: -1px;
    overflow: hidden;
    object-fit: cover;
}

/* -------------------------------------------------------  why_choose_us  --------------------------------------------------------- */


.why_choose_us {
    padding-top: 110px;
    padding-bottom: 110px;
    background: rgba(0, 0, 0, 1);
    padding-left: 20px;
    /* margin-top: 110px; */
}

.why_choose_us_row {
    width: 100% !important;
    margin: auto;
    /* margin-top: 110px; */
}

.why_choose_us_row .col_main_1 {
    padding-left: 0;
}

.why_choose_us_row .img_div {
    position: relative;
    width: 430px;
    margin: auto;
    width: 100%;
}

.why_choose_us_row .img_div img {
    width: 100%;
    height: 100%;
    /* transform: scaleX(-1); */
    margin: auto;
    border-radius: 30px;
    margin-left: 6px !important;
    display: block;
}

.why_choose_us_row .img_div .experience_div {
    display: none !important;
    position: absolute;
    top: -22px;
    background: linear-gradient(rgba(229, 174, 6, 1), rgba(164, 144, 72, 1));
    display: flex;
    left: 49px;
    flex-direction: column;
    width: 118px;
    padding-left: 25px;
    height: 72px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    justify-content: center;
    padding-bottom: 1px;
}

.why_choose_us_row .img_div .experience_div span {
    font-size: 10px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
}

.why_choose_us_row .col_main_2 {
    padding-right: 50px;
}

.why_choose_us_row .col_main_2>h3 {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
}

.why_choose_us_row .col_main_2>h5 {
    color: rgba(234, 181, 20, 1);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    text-transform: uppercase;
    margin: 20px 0px 24px 0px;
    width: 83%;
    /* line-height: 37px; */
    background: linear-gradient(89.73deg, #ECC67D 0.07%, #D6A445 99.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);

}

.why_choose_us_row .col_main_2>p {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 23px;
    text-transform: capitalize;
    margin-bottom: 40px;
}

.why_choose_us_row .col_main_2>ul {
    list-style: none;
    margin-top: 30px;
    padding-left: 90px;
}

.why_choose_us_row .col_main_2>ul li {
    margin-bottom: 35px;
    position: relative;
}

.why_choose_us_row .col_main_2>ul li::after {
    position: absolute;
    top: -9px;
    left: -90px;
}

.why_choose_us_row .col_main_2>ul li:nth-child(1)::after {
    content: url(../../Assets/quality_of_work_symbol.svg);
}

.why_choose_us_row .col_main_2>ul li:nth-child(2)::after {
    content: url(../../Assets/trained_ready_symbol.svg);
}

.why_choose_us_row .col_main_2>ul li:nth-child(3)::after {
    content: url(../../Assets/support_symbol.svg);
}

.why_choose_us_row .col_main_2>ul li h6 {
    margin-bottom: 15px;
    color: rgba(190, 152, 19, 1);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    text-transform: capitalize;
}

.why_choose_us_row .col_main_2>ul li p {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    width: 90%;
    text-transform: capitalize;
}


/* -------------------------------------------------------  bpo_benfits  --------------------------------------------------------- */


.bpo_benfits {
    /* background-color: #87cfeb00; */
    padding-top: 125px;
    background: url("../../Assets/bpo_benifits_diffreence_background.svg");
    /* background-repeat: no-repeat; */
    position: relative;
    background-size: cover;
    z-index: 5;
    padding-bottom: 125px;
}


.bpo_benfits::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #00000085;
    width: 100%;
    height: 100%;
    z-index: 10;
}


.bpo_benfits_row {
    width: 86% !important;
    margin: auto;
    margin-top: 40px;
    z-index: 20;
    position: relative;
}


.bpo_benfits .col_main h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 65px;
    text-align: left;
    text-transform: uppercase;
    background: linear-gradient(89.46deg, #D6A445 5.94%, #E6C483 25.59%, #FFCD6D 72.49%, #C6A25C 91.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
    width: 95%;
}

.bpo_benfits_row .col_main p {
    color: rgba(252, 245, 224, 1);
    font-size: 23px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 35px;
    width: 90%;
}

.bpo_benfits_row .col_main ul {
    /* padding: 0; */
    list-style: none;
    margin-top: 35px;
    padding-left: 40px;
}

.bpo_benfits_row .col_main ul li {
    color: rgba(252, 245, 224, 1);
    font-size: 23px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    position: relative;
    margin-bottom: 10px;
}

.bpo_benfits_row .col_main ul li::before {
    content: url(../../Assets/list_symbol.svg);
    position: absolute;
    top: 3px;
    left: -41px;
}

.bpo_benfits_row .col_main_2 {
    padding-bottom: 70px;
    padding-left: 65px;
}

.bpo_benfits_row .Know_more {
    background-color: rgba(234, 181, 20, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 9px 17px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}


/* -------------------------------------------------------  contact_technology  --------------------------------------------------------- */


.contact_technology_main {
    width: 100% !important;
    margin: auto;
    position: relative;
    margin-top: 90px;
}

.contact_technology_main img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    object-position: top;
}

/* .contact_technology_main::before{
    content: "";
    background: rgba(57, 53, 53, 0.36) ;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
} */

.contact_technology_main .sub_box_div {
    background-color: rgba(255, 255, 255, 0.5);
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    padding: 20px 25px;
    border-radius: 7px;
    width: 480px;
    position: absolute;
    bottom: 20px;
    left: 30px;
    border-bottom-right-radius: 47px;
    z-index: 10;
    height: 249px;
}

.contact_technology_main .sub_box_div h6 {
    color: rgba(240, 73, 21, 1);
    color: white;
    text-shadow: 1px 1px 6px #000000b5;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    line-height: 27px;
    text-transform: uppercase;
}

.contact_technology_main .sub_box_div p {
    /* color: rgba(0, 0, 0, 1); */
    color: white;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 25px;
}

.contact_technology_main .sub_box_div button {
    /* background: rgba(234, 181, 20, 1); */
    background: transparent;
    /* color: rgba(255, 255, 255, 1); */
    /* font-size: 13px; */
    /* font-weight: 600; */
    /* font-family: 'Inter', sans-serif; */
    border: none;
    padding: 6px 19px;
    border-radius: 50px;
    cursor: pointer;
    /* text-transform: uppercase; */
    border: 2px solid white;
}

.contact_technology_main .sub_box_div button a {
    /* background: rgba(234, 181, 20, 1); */
    color: rgba(255, 255, 255, 1);
    font-size: 13px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    /* border: none; */
    /* padding: 6px 19px; */
    /* border-radius: 50px; */
    /* cursor: pointer; */
    text-transform: uppercase;
}


/* -------------------------------------------------------  accordion_section  --------------------------------------------------------- */

.accordion_section {
    width: 80%;
    margin: auto;
    margin-top: 150px;
    margin-bottom: 150px;
}



/* -------------------------------------------------------  follow_us  --------------------------------------------------------- */


.follow_us {
    padding-bottom: 40px;
    text-align: center;
}

.follow_us h2 {
    margin-top: 50px;
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.follow_us ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;
    padding: 0;
}

.follow_us ul li a {
    width: 60px;
    height: 60px;
    /* background-color: rgba(234, 181, 20, 1); */
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    display: inline-block;
    border-radius: 50%;
    margin: 24px 20px 0px;
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.follow_us ul li a svg {
    filter: drop-shadow(1px 2px 5px #000000c4);
}

/* .What_we_do h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 46px;
} */
.What_we_do  h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 65px;
    text-align: left;
    text-transform: uppercase;
    background: linear-gradient(89.46deg, #D6A445 5.94%, #E6C483 25.59%, #FFCD6D 72.49%, #C6A25C 91.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
    width: 95%;
    z-index: 20;
    position: relative;
}

.our_clients .our_clients_row .col_main .card {
    background: rgba(81, 78, 78, 1);
    box-shadow: 4px 3px 10px rgb(0 0 0 / 19%);
    border: none;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 25px 31px 25px;
    margin: auto;
    margin-bottom: 100px;
    gap: 20px;
    height: 330px;
}

.our_clients .our_clients_row .col_main .card .img_div img {
    width: 100%;
    border-radius: 15px;
}

.our_clients_row {
    padding: 0 10px;
}

.our_clients .our_clients_row .col_main .card .img_div {
    width: 150px;
    box-shadow: 10px 10px 11px 1px;
}

.our_clients h5 {
    font-size: 22px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.our_clients h2 {
    text-align: center;
    margin-top: 50px;
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 700;
}

/* old-code */
video::-webkit-media-controls-fullscreen-button {
    opacity: 0;
}

video::-webkit-media-controls-play-button {
    display: none;
}

video::-webkit-media-controls-timeline {
    display: none;
}

video::-webkit-media-controls-current-time-display {
    display: none;
}

video::-webkit-media-controls-time-remaining-display {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-start-playback-button {
    display: none;
}

video::-webkit-media-controls-panel {
    background: transparent;
}


.bnenefits_for_bpo_col {
    position: relative;
    padding-right: 70px;
}

.difference_for_bpo_col {
    padding-left: 70px;
}

.bnenefits_for_bpo_col h2,
.difference_for_bpo_col h2 {
    height: 80px;
}

/* .bnenefits_for_bpo_col::after{
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    top: -59px;
    right: -10px;
    background: #eab514;
    transform: rotate(10deg);
} */

.testimonials_col1 {
    position: relative;
    padding-right: 30px;
}

.testimonials_col1 {
    padding-left: 30px;
    text-align: left;
    padding-left: 0;
    padding-right: 80px;
    /* align-self: flex-end; */
    align-items: flex-end;
    justify-content: flex-end;
}

.testimonials_col1::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    top: 10px;
    right: 17px;
    background: #eab514;
    transform: rotate(10deg);
}


.testimonials_col2 ul li::before {
    display: none;
}

.testimonials_col2,
.testimonials_col1 {
    padding-bottom: 0 !important;
    height: 300px;
}

.testimonials_carousel {
    /* height: 60%; */
}

.testimonials_carousel .react-multiple-carousel__arrow--left {
    left: 0px;
    bottom: 0;
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
}

.testimonials_carousel .react-multiple-carousel__arrow--right {
    left: 60px;
    bottom: 0;
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    right: initial;
}

.testimonials_carousel .react-multiple-carousel__arrow--left:hover,
.testimonials_carousel .react-multiple-carousel__arrow--right:hover {
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    opacity: 0.5;
}

.testimonials_col2 ul li p,
.testimonials_col2 ul li div {
    overflow: hidden;
    text-align: left;
}


.testimonials_col2 ul li {
    /* width: 613px !important; */
    margin-right: 2px;
    padding: 0px 19px;
}

.accordtion_why_choose_us,
.bottom_accordion_main,
.accordtion_why_choose_us .accordion-item,
.bottom_accordion_main .accordion-item {
    border: none;
    /* background: #393535; */
    background: transparent;

}

.accordtion_why_choose_us .accordion-item,
.bottom_accordion_main .accordion-item {
    margin-bottom: 20px;
}

.accordtion_why_choose_us .accordion-header,
.bottom_accordion_main .accordion-header {
    border: none;
}

.accordtion_why_choose_us .accordion-header button {
    /* background: #393535; */
    background: transparent;
    margin-bottom: 15px;
    color: rgba(190, 152, 19, 1);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    text-transform: capitalize;
    padding: 0;
    margin-bottom: 0;
    box-shadow: none;
    background: linear-gradient(89.53deg, #ECC475 0.17%, #E2AF49 99.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
}


.why_choose_us_row .accordion-item .accordion-header button::before {
    /* position: absolute;
    top: -9px;
    left: -90px; */
    margin-right: 20px;
}

.why_choose_us_row .accordion-item .accordion-header button::after {
    background-image: url('../../Assets/accordion_icon_arrow.png');
    margin-right: 25px;
}

.why_choose_us_row .accordion-item:nth-child(1) .accordion-header button::before {
    content: "";
    /* position: absolute; */
    width: 40px;
    height: 40px;
    padding: 20px;
    background: url(../../Assets/quality_of_work_symbol_new.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.why_choose_us_row .accordion-item:nth-child(2) .accordion-header button::before {
    content: "";
    /* position: absolute; */
    width: 40px;
    height: 40px;
    padding: 20px;
    background: url(../../Assets/trained_ready_symbol_new.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.why_choose_us_row .accordion-item:nth-child(3) .accordion-header button::before {
    content: "";
    /* position: absolute; */
    width: 40px;
    height: 40px;
    padding: 20px;
    background: url(../../Assets/support_symbol_new.svg);
    background-size: cover;
    background-repeat: no-repeat;
}

.why_choose_us_row .accordion-collapse,
.bottom_accordion_main .accordion-collapse {
    /* background: #393535; */
    background: transparent;
}

.why_choose_us_row .accordion-body {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    width: 100%;
    text-transform: capitalize;
    background: transparent;
    padding: 5px 25px;
    margin-bottom: 20px;
    margin-top: 10px;
    border: none;
    position: relative;
    bottom: 0px;
    border-radius: 10px;
    filter: drop-shadow(1px 2px 7px #0000008f);
    text-shadow: 1px 2px 7px #0000008f;
}

.bottom_accordion_main .accordion-header button {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    width: 100%;
    text-transform: capitalize;
    background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
    /* background: rgba(234, 181, 20, 1); */
    padding: 20px 25px;
    margin-bottom: 20px;
    margin-top: 10px;
    border: none;
    position: relative;
    bottom: 0px;
    border-radius: 5px;
    filter: drop-shadow(1px 2px 7px #0000008f);
    text-shadow: 1px 2px 7px #0000008f;
    box-shadow: none;
}

.bottom_accordion_main .accordion-body {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
}

.bottom_accordion_main .accordion-item .accordion-header button::after {
    background-image: url('../../Assets/accordion_Arrow_icon_white.png');
}

/* old-code end */



@media(max-width:1300px) {
    .top_card_main .card {
        height: 400px;
    }

    .top_card_main .card2,
    .top_card_main .card3 {
        height: 390px;
    }

    .smart_Solution_row2 {
        padding-bottom: 133px;
    }


    .What_we_do_row .col_main_2 button {
        top: 50%;
    }

}

@media(max-width:1200px) {

    .smart_Solution_row .col_main_1 h2,
    .smart_Solution_row .col_main_1 p {
        width: 100%;
    }

    .smart_Solution_row {
        /* padding-bottom: 180px; */
        padding-top: 35px;
    }

    .smart_Solution_row img {
        width: 100%;
    }

    .smart_Solution_row2 {
        padding-bottom: 195px;
        /* padding-top: 80px; */
    }

    .smart_Solution_row .col_main_1 {
        padding-top: 45px;
    }

    .top_card_main .card2,
    .top_card_main .card3 {
        width: 100%;
        /* padding: 18px 18px 0px 18px; */
    }

    .top_card_main .card2 p,
    .top_card_main .card3 p {
        font-size: 17px;
        margin-top: 14px;
    }

    .top_card_main .card1 {
        width: 100%;
    }

    .top_center_card_row {
        bottom: -250px;
    }

    .What_we_do {
        padding-top: 320px;
    }

    .why_choose_us_row {
        margin-top: 35px;
    }

    .What_we_do_row {
        width: 95% !important;
    }

    .why_choose_us_row .img_div {
        width: 100%;
    }

    .why_choose_us_row .img_div .experience_div {
        top: -22px;
        left: 35px;
    }

    .What_we_do_row .col_main_2 button {
        top: 44%;
    }


}


@media(max-width:992px) {


    .smart_Solution_row {
        /* padding-bottom: 180px; */
        padding-top: 75px;
    }

    .smart_Solution_row2 {
        padding-bottom: 190px;
    }

    .smart_Solution_row .col_main_1 {
        padding-top: 0;
    }

    .What_we_do_row {
        width: 100% !important;
    }

    .What_we_do_row .col_main_2 .video_div {
        height: 325px;
    }

    .What_we_do_row .col_main_2 button {
        width: 60px;
        height: 60px;
        left: 15px;
        top: 34%;
        font-size: 18px;
    }

    /* .why_choose_us_row .img_div .experience_div {
        width: 112px;
        padding-left: 25px;
        height: 63px;
        padding-bottom: 1px;
    } */


    .why_choose_us_row .img_div .experience_div span {
        font-size: 10px;
    }

    .why_choose_us_row .col_main_2 {
        padding-left: 29px;
        padding-right: 0;
    }

    .top_card_main .card2 h5,
    .top_card_main .card3 h5 {
        font-size: 24px;
    }

    .top_center_card_row .our_mission_img {
        width: 55px;
        top: 14px;
        left: 61px;
    }

    .top_center_card_row .our_mission_span {
        left: 40px;
    }

    .top_center_card_row .our_vision_img {
        width: 53px;
        top: 16px;
        left: 58px;
    }

    .top_card_main .card2 p,
    .top_card_main .card3 p {
        font-size: 17px;
        line-height: 23px;
        margin-top: 7px;
    }

    .top_card_main .card2,
    .top_card_main .card3 {
        height: 400px;
    }

    .why_choose_us_row .col_main_1 {
        margin: auto;
    }

    .why_choose_us_row .col_main_2 {
        padding-left: 0;
        margin-top: 60px;
    }

    /* .why_choose_us_row .img_div img {
        width: 100%;
    }

    .why_choose_us_row .img_div .experience_div {
        top: -25px;
        left: 74px;
    }

    .why_choose_us_row .img_div{
        height: 552px;
    } */

    .why_choose_us_row .img_div {
        width: 85%;
        margin: auto;
    }

    .smart_Solution_row img {
        position: absolute;
        top: 0;
        z-index: 5;
        height: 100%;
        width: 62%;
        margin: auto;
        display: block;
        left: 50%;
        transform: translate(-50%, 0);
        display: none;
    }

    /* .smart_Solution_row .col_main_1{
        position: relative;
        background: ;
    } */

    .why_choose_us_row .img_div img {
        width: 430px;
        height: 550px;
    }

    .why_choose_us_row .img_div .experience_div {
        top: -22px;
        left: 51px;
    }

    .top_card_main .card2,
    .top_card_main .card3 {
        padding: 20px 20px 0px 20px;
    }

    .our_clients_row {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .our_clients .our_clients_row .col_main .card {
        margin-bottom: 30px;
        gap: 10px;
    }

    .testimonials_col2 ul li {
        /* width: 548px !important; */
        margin-right: 2px;
        padding: 0px 19px;
    }
    .what_we_do_grid .what_we_do_grid_item3 .item3_main>span {
        font-size: 18px;
    }

    .bpo_benfits_row {
    width: 98% !important;
    margin: auto;
    margin-top: 40px;
    z-index: 20;
    position: relative;
}

.bpo_benfits .col_main h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
    text-transform: uppercase;
    background: linear-gradient(89.46deg, #D6A445 5.94%, #E6C483 25.59%, #FFCD6D 72.49%, #C6A25C 91.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
    width: 95%;
}

.What_we_do .col_main h2 {
    color: rgba(234, 181, 20, 1);
    font-family: 'Inter', sans-serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 65px;
    text-align: left;
    text-transform: uppercase;
    background: linear-gradient(89.46deg, #D6A445 5.94%, #E6C483 25.59%, #FFCD6D 72.49%, #C6A25C 91.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(1px 2px 2px #0000008f);
    width: 100%;
    align-self: center;
}
.What_we_do_row .col_main_1{
    padding-left: 20px;
}

}

@media(max-width:767.98px) {
    .smart_Solution_row2 {
        display: none;
    }

    /* .why_choose_us_row .img_div .experience_div {
        top: -1px;
        left: 29px;
    } */

    .smart_Solution_row img {
        position: relative;
        top: initial;
        left: initial;
        transform: none;
    }

    .top_card_main .card2::before,
    .top_card_main .card3::before {
        background: transparent;
    }

    .top_card_main .card1 {
        background-position: bottom;
    }

    .What_we_do_row {
        /* flex-direction: column-reverse; */
        width: 90% !important;
    }

    .What_we_do_row .col_main_1 {
        margin-top: 0;
    }

    .why_choose_us_row .col_main_2 {
        padding-left: 0;
        margin-top: 60px;
    }

    .why_choose_us_row .col_main_1 {
        margin: auto;
    }

    /* .What_we_do h2 {
        font-size: 34px;
    } */

    .contact_technology_main {
        height: 400px;
    }

    .smart_Solution_row {
        flex-direction: column-reverse;
        padding-bottom: 55px;
    }

    .smart_Solution_row img {
        width: 85%;
        position: relative;
        bottom: 41px;
        margin: auto;
        display: block;

    }

    .top_center_card_row {
        position: relative;
        bottom: 0;
        justify-content: center;
    }

    .parallel_card {
        margin-top: 36px;
    }


    .top_card_main .card {
        height: 480px !important;
        /* margin-bottom: 36px; */
        padding: 46px 20px 0px 20px;
    }

    .smart_Solution_row2 {
        padding-bottom: 70px;
    }

    .What_we_do {
        padding-top: 70px;
    }

    .What_we_do_row .col_main_2 button {
        left: 18px;
        top: 64%;
        font-size: 18px;
    }

    .top_card_main .card2 p,
    .top_card_main .card3 p {
        font-size: 18px;
    }

    .top_card_main .card2 h5,
    .top_card_main .card3 h5 {
        font-size: 24px;
    }


    .top_center_card_row .our_mission_img {
        width: 65px;
        top: 26px;
        left: 58px;

    }

    .top_center_card_row .our_mission_span {
        left: 43px;
    }

    .top_center_card_row .our_vision_img {
        width: 60px;
        top: 37px;
        left: 55px;
    }

    .why_choose_us_row .img_div {
        width: 100%;
        margin: auto;
    }

    .header {
        padding: 1px 8px;
        padding-right: 10px;
    }

    .header .nav_brand {
        width: 141px;
    }

    .why_choose_us_row .img_div .experience_div {
        width: 109px;
    }


    .why_choose_us_row .img_div {
        width: 430px;
        height: 550px;
    }

    .why_choose_us_row .img_div img {
        width: 100%;
        height: 100%;
    }

    .contact_technology_main img {
        height: 100%;
    }
    .expertice_row .col_main_2{
        padding-left: 0;
    }
    .difference_for_bpo_col {
        padding-left: 12px !important;
    }
    .testimonials_col1::after {
      display: none;
    }
    .What_we_do .col_main h2 {
        text-align: center;
    }
    .testimonials_carousel .react-multiple-carousel__arrow--left {
        left: 35%;
        bottom: 5%;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        /* right: initial; */
    }
    .testimonials_carousel .react-multiple-carousel__arrow--right {
        left: 55%;
        bottom: 5%;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        /* right: initial; */
      }
      .testimonials_col1{
        padding-right: 0 !important;
      }
      .What_we_do_row .col_main ul {
        /* padding: 0; */
        list-style: none;
        margin-top: 0;
        padding-left: 0;
    }


}

@media(max-width:576px) {


    .top_card_main .card2 p,
    .top_card_main .card3 p {
        line-height: normal;
    }

    .contact_technology_main {
        height: 420px;
    }

    .contact_technology_main .sub_box_div {
        transform: translate(-50%, 0px);
        left: 5%;
        width: 90%;
        height: auto;
    }

    .top_card_main .card {
        height: auto !important;
        padding-bottom: 18px;
    }

    .top_card_main .card1 {
        height: 330px !important;
        padding-bottom: 18px;
        background-position: top;
    }

    .smart_Solution_row {
        padding: 0px 12px;
        /* padding-bottom: 70px; */
        padding-top: 10px;
        flex-direction: column-reverse;
    }

    .smart_Solution_row2 {
        padding: 0px 12px;
        padding-bottom: 70px;
        /* padding-top: 80px; */
    }

    .smart_Solution_row .col_main_1 h2 {
        font-size: 30px;
    }

    .smart_Solution_row .col_main_1 p {
        font-size: 20px;
    }

    .smart_Solution_row .col_main_1 button {
        font-size: 18px;
        padding: 7px 21px;
    }

    .smart_Solution_row img {
        top: 0;
        margin-bottom: 45px;
        width: 100%;
    }

    .top_card_main {
        justify-content: center;
        margin-top: 70px;

    }

    .What_we_do h2 {
        /* font-size: 28px; */
        line-height: 39px;

    }

    .What_we_do_row {
        width: 100% !important;
    }

    .What_we_do_row .col_main_2 button {
        width: 50px;
        height: 50px;
        left: 26px;
        top: 65%;
        font-size: 16px;
    }

    .What_we_do_row .col_main_2 .video_div {
        width: 94%;
        height: 228px;
        border: 13px solid rgba(255, 255, 255, 1);
        border-top-right-radius: 59px;
        border-bottom-left-radius: 59px;
    }

    .What_we_do_row .Know_more {
        font-size: 18px;
        padding: 7px 21px;
    }

    .What_we_do_row .col_main_1 {
        padding-bottom: 45px;
    }

    .why_choose_us_row {
        width: 100% !important;
    }


    .why_choose_us_row .img_div .experience_div {
        width: 88px;
        padding-left: 19px;
        height: 61px;
        padding-bottom: 1px;
        border-top-right-radius: 32px;
        border-bottom-left-radius: 32px;
        top: -23px;
        left: 22px;
    }

    .why_choose_us_row .img_div {
        width: 320px;
        margin: auto;
        height: initial;
    }

    .why_choose_us_row .img_div .experience_div span {
        font-size: 9px;
    }


    .why_choose_us_row .col_main_2>h3 {
        font-size: 18px;
    }

    .why_choose_us_row .col_main_2>h5 {
        font-size: 21px;
    }

    .why_choose_us_row .col_main_2>p {
        font-size: 16px;
    }

    .why_choose_us_row .col_main_2>ul li h6 {
        font-size: 18px;
    }

    .why_choose_us_row .col_main_2>ul li p {
        font-size: 16px;
    }

    .contact_technology_main .sub_box_div h6 {
        font-size: 16px;
        line-height: 21px;
    }

    .contact_technology_main .sub_box_div p {
        font-size: 15px;
        line-height: 21px;
        margin-top: 15px;
        margin-bottom: 14px;
    }

    .follow_us ul li a {
        margin: 24px 18px 0px;
    }

    .why_choose_us_row .img_div img {
        width: 100%;
        height: initial;
    }

    .What_we_do_row .col_main p {
        font-size: 18px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .What_we_do_row .col_main ul li {
        font-size: 18px;
    }

    .follow_us h2 {
        font-size: 28px;
    }

    .why_choose_us_row .col_main_2 {
        padding-left: 15px;
        padding-right: 15px;
    }


    .why_choose_us_row .col_main_2>ul li::after {
        position: absolute;
        top: 0;
        left: 0;
    }

    .why_choose_us_row .col_main_2>ul {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .why_choose_us_row .col_main_2>ul li {
        padding-top: 69px;
    }

    .testimonials_carousel .react-multiple-carousel__arrow--left {
        left: 40%;
        bottom: 15%;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        /* right: initial; */
    }
    .testimonials_carousel .react-multiple-carousel__arrow--right {
        left: 60%;
        bottom: 15%;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        /* right: initial; */
      }
 
    .accordion_section {
        width: 94%;
        margin: auto;
        margin-top: 150px;
        margin-bottom: 150px;
    }

    .difference_for_bpo_col {
        padding-left: 12px !important;
    }
    .expertice_row .col_main_2 {
        /* padding-bottom: 70px; */
        padding-left: 0;
    }
    .testimonials_carousel {
        /* height: 60%; */
       position: static !important;
    }
 
    
}

@media(max-width:425px) {

    .follow_us ul li a {
        width: 50px;
        height: 50px;
        margin: 24px 11px 0px;
        font-size: 24px;
    }

    .follow_us h2 {
        margin-top: 40px;
    }

    .follow_us {
        padding-bottom: 30px;
    }

    .what_we_do_grid .what_we_do_grid_item3 .item3_main>span {
        font-size: 14px;
    }
    
      .What_we_do .col_main h2{
        font-size: 34px;
        text-align: center;     
    }
}

@media(max-width:370px) {
    .why_choose_us_row .img_div {
        width: 95%;
    }
  
    .testimonials_col1{
        padding-right: 0 !important;
    }
    .testimonials_carousel .react-multiple-carousel__arrow--left {
        left: 40%;
        bottom: 0;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        /* right: initial; */
    }
    .testimonials_carousel .react-multiple-carousel__arrow--right {
        left: 60%;
        bottom: 0;
        background: linear-gradient(86deg, #C29541 0%, #FFCD6D 51.48%, #C29541 98.93%);
        /* right: initial; */
      }
}