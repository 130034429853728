input::placeholder,
textarea::placeholder {
    font-size: 20px;
}

input[type="number"]:hover::-webkit-inner-spin-button,
input[type="number"]:hover::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 0;
}

/* ----------------------------------------------------------  Top_seciton  -------------------------------------------- */

.careers_main .Top_seciton {
    /* height: 300px; */
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px 60px 240px 60px;
    position: relative;

}

.careers_main .Top_seciton .img_div {
    position: absolute;
    top: 190px;
    width: 85%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 500px;
}

.careers_main .Top_seciton .img_div img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
}

/* 
.careers_main .Top_seciton{
    height: 300px;
    background-color: rgba(251, 229, 202, 1);
    text-align: center;
    padding: 60px;
} */

.careers_main .Top_seciton .main_title {
    color: rgba(234, 181, 20, 1);
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.careers_main .Top_seciton .breadcrumbs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}

.careers_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow {
    color: rgba(234, 181, 20, 1);
    font-size: 26px;
    position: relative;
    cursor: pointer;
}

.careers_main .Top_seciton .breadcrumbs a {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 0px 15px;
    text-transform: uppercase;
}



/* ----------------------------------------------------------  careers_description  -------------------------------------------- */


.careers_description {
    width: 88%;
    margin: auto;
    margin-top: 390px;
    text-align: left;
    margin-bottom: 80px;
}

.careers_description>h2 {
    color: rgba(243, 179, 23, 1);
    font-size: 28px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin-top: 12px;
    /* margin-bottom: 35px; */
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    /* line-height: 32px; */
    /* margin-top: 30px; */
    text-align: center;
}

.careers_form {
    margin-top: 50px;
}

.careers_form .input_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.careers_form .input_row .input_div {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.careers_form .input_row .input_div:last-child {
    float: right;
}

.careers_form>.input_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
}

.careers_form .input_div label {
    color: rgba(234, 181, 20, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    /* text-transform: capitalize; */
}

/* .careers_form .input_row .input_div input ,  .careers_form > .input_div > textarea{
    color: rgba(234, 181, 20, 1);
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
} */

.careers_form .input_row .input_div input,
.careers_form .input_row .input_div input {
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border: 2px solid rgba(234, 181, 20, 1);
    border-radius: 12px;
    background: transparent;
    padding: 2px 17px;
    width: 100%;
    height: 57px;
    margin-top: 10px;
}

.careers_form>.input_div input,
.careers_form>.input_div textarea {
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    border: 2px solid rgba(234, 181, 20, 1);
    border-radius: 12px;
    background: transparent;
    padding: 2px 17px;
    width: 100%;
    height: 57px;
    margin-top: 10px;
}

.careers_form>.input_div textarea {
    height: 150px;
    padding: 10px 17px;
    resize: none;
}

.careers_form button {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    background: rgba(234, 181, 20, 1);
    border-radius: 6px;
    border: none;
    padding: 11px 60px;
    margin: auto;
    display: block;
    margin-top: 60px;
}


/* -------------------------------------------------------  come_work_Withus  --------------------------------------------------------- */



.come_work_Withus_row {
    width: 91% !important;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 110px;
}


.come_work_Withus h1{
    color: rgba(243, 179, 23, 1);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
}

.come_work_Withus h4{
    color: rgba(243, 179, 23, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    text-align: center;
}

.come_work_Withus_row .img_div {
    position: relative;
    width: 430px;
    margin: auto;
}

.come_work_Withus_row .img_div img {
    width: 100%;
    /* transform: scaleX(-1); */
    margin: auto;
    display: block;
}

.come_work_Withus_row .col_main_2 {
    padding-left: 50px;
}

.come_work_Withus_row .col_main_2>h3 {
    color: rgba(243, 179, 23, 1);
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 36px;

}

.come_work_Withus_row .col_main_2>p {
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.come_work_Withus_row .col_main_2>ul {
    list-style: none;
    margin-top: 50px;
    padding-left: 80px;
}

.come_work_Withus_row .col_main_2>ul li {
    margin-bottom: 35px;
    position: relative;
}

.come_work_Withus_row .col_main_2>ul li::after {
    position: absolute;
    top: -9px;
    left: -90px;
}

.come_work_Withus_row .col_main_2>ul li:nth-child(1)::after {
    content: url(../../Assets/quality_of_work_symbol.svg);
}

.come_work_Withus_row .col_main_2>ul li:nth-child(2)::after {
    content: url(../../Assets/trained_ready_symbol.svg);
}

.come_work_Withus_row .col_main_2>ul li:nth-child(3)::after {
    content: url(../../Assets/support_symbol.svg);
}

.come_work_Withus_row .col_main_2>ul li h6 {
    margin-bottom: 15px;
    color: rgba(190, 152, 19, 1);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    text-transform: capitalize;
}

.come_work_Withus_row .col_main_2>ul li p {
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    width: 90%;
    text-transform: capitalize;
}




/* ----------------------------------------------------------  media querry  -------------------------------------------- */


@media(min-width:1600px) {

    .careers_main .Top_seciton .img_div img {
        width: 85%;
    }

}

@media(max-width:1200px) {

    .come_work_Withus_row {
        margin-top: 75px;
    }

    .come_work_Withus_row .img_div img {
        width: 100%;
    margin: auto;
    display: block;
    height: 100%;
    }

    .come_work_Withus_row .img_div{
        width: 100%;
        margin: auto;
        display: block;
        height: 100%;
    }

    .come_work_Withus_row .col_main_2>ul {
        padding-left: 86px;
    }

}

@media(max-width:1000px) {

    .careers_main .Top_seciton .img_div {
        width: 90%;
        height: 400px;
    }


    .careers_description {
        margin-top: 250px;
    }


}

@media(max-width:991px) {


    .come_work_Withus_row .col_main_2 {
        padding-left: 29px;
    }

    .come_work_Withus_row .col_main_1 {
        margin: auto;
    }

    .come_work_Withus_row .col_main_2 {
        /* padding-left: 0; */
        margin-top: 60px;
    }

    .come_work_Withus_row .img_div {
        /* width: 85%; */
        margin: auto;
    }

    .come_work_Withus_row .img_div img {
        width: 430px;
        height: 500px;
    }

    .come_work_Withus_row {
        flex-direction: column-reverse;
    }

    .careers_form .input_row .input_div{
        width: 48%;
    }

}


@media(max-width:767px) {

    .careers_main .Top_seciton {
        padding: 69px 0px 203px 0px;
    }

    .careers_main .Top_seciton .main_title {
        font-size: 30px;
    }

    .careers_main .Top_seciton .breadcrumbs a {
        font-size: 18px;
    }

    .come_work_Withus_row .col_main_2 {
        /* padding-left: 0; */
        margin-top: 60px;
    }

    .come_work_Withus_row .col_main_1 {
        margin: auto;
    }

    .come_work_Withus_row .img_div {
        width: 100%;
        margin: auto;
    }

    .come_work_Withus_row .img_div {
        width: 430px;
        height: 480px;
    }

    .come_work_Withus_row .img_div img {
        width: 100%;
        height: 100%;
    }

    .careers_form .input_row .input_div{
        width: 48%;
    }

    .come_work_Withus h4 {
        font-size: 30px;
    }

    .careers_description {
        margin-top: 300px;
    }

}

@media(max-width:575px) {

    .careers_main .Top_seciton .img_div {
        width: 90%;
        height: 300px;
        top: 196px;
    }


    .careers_description {
        margin-top: 200px;
    }

    .come_work_Withus_row {
        width: 100% !important;
    }

    .come_work_Withus_row .img_div {
        width: 100%;
        margin: auto;
        height: initial;
    }

    .come_work_Withus_row .col_main_2>h3 {
        font-size: 20px;
    }

    .come_work_Withus_row .col_main_2>h5 {
        font-size: 21px;
    }

    .come_work_Withus_row .col_main_2>p {
        font-size: 18px;
    }

    .come_work_Withus_row .col_main_2>ul li h6 {
        font-size: 20px;
    }

    .come_work_Withus_row .col_main_2>ul li p {
        font-size: 18px;
    }

    .come_work_Withus_row .img_div img {
        width: 100%;
        height: 400px;
    }

    .careers_form .input_row{
        flex-direction: column;
    }

    .careers_form .input_row .input_div{
        width: 100%;
    }

    .come_work_Withus_row .col_main_2>ul li::after {
        position: absolute;
        top: 0;
        left: 0;
    }

    .come_work_Withus_row .col_main_2>ul {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .come_work_Withus_row .col_main_2>ul li {
        padding-top: 69px;
    }

    .careers_form button {
        margin: initial;
        display: block;
        margin-top: 38px;
        padding: 10px 18px;
    }
    
    .careers_form {
        margin-top: 30px;
    }

    .come_work_Withus {
        padding: 0px 10px;
    }
    

}

.careers_main .Top_seciton .breadcrumbs .breadcrumbs_Arrow:hover{
    transform: translateX(0.5rem);
}